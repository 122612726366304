import ISubscriptionPlan from "../../interfaces/subplan";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import Price from "../Prices/Price";

const UpgradePrices = () => {
  const { currentPlan, plans } = useDashboardContext();
  return (
    <div>
      <div className="my-16 md:grid md:grid-cols-2 gap-8 w-full justify-center max-w-6xl space-y-4 md:space-y-0">
        {plans.map((p: ISubscriptionPlan, index: number) => {
          return (
            <Price index={index} key={index} {...p} selected={currentPlan?.id === p.id} />
          );
        })}
      </div>
    </div>
  );
};

export default UpgradePrices;
