import { Link } from "react-router-dom";

const data = [
  {
    id: "1",
    header: "1. Introduction",
    body: (
      <div className="space-y-5">
        <p>
        Welcome to XDistro Music Limited (“XDistro Music”, “Company”, “we”,
        “our”, “us”)!
        </p>
        <p className="">
          These Terms of Service (“Terms”, “Terms of Service”) govern your use
          of our web pages located at{" "}
          <Link to="/" className="text-link">https://xdistromusic.com</Link>{" "}
          operated by XDistro Music Limited of 223 Digbeth Square, 10 Lombard
          Street, Birmingham, B12 0QD.
        </p>
        <p>
          Our Privacy Policy also governs your use of our Service and explains how we collect,
          safeguard and disclose information that results from your use of our web pages.
          Please read it here {" "}
          <Link to="/privacypolicy">https://xdistromusic/privacypolicy.</Link>{" "}
        </p>
        <p>
          Your agreement with us includes these Terms and our Privacy Policy (“Agreements”).
          You acknowledge that you have read and understood Agreements, and agree to be bound of them.
        </p>
        <p>
          If you do not agree with (or cannot comply with) agreements,
          then you may not use the Service. These Terms apply to all visitors,
          users and others who wish to access or use our Service
        </p>
      </div>
    ),
  },
  {
    id: "2",
    header: "2. Grant of Rights",
    body: (
      <div>
        In order for us to provide the Service and to distribute your Recordings
        (which, solely for purposes of clarity, and as a reminder to you,
        includes the Compositions) and related content to your selected Digital
        Stores via our Service and/or on XDistro Music’s sites/services or
        digital platforms, you hereby confirm that you grant us:
        <p className="my-3">
          a. Non-exclusive world-wide license to use, reproduce, distribute,
          display, publicly perform, exhibit, broadcast and transmit and make
          available by all means and media (whether now known or existing in the
          future) the Works for distribution in the Service and for all
          promotional purposes including without limitation for the purposes of
          internet radio broadcast; and use, reproduce and display in the
          Service any trade marks, service marks or trade names relating to you
          and the name and likeness of you and the band/artist whose
          performances are embodied in the works for the term of this Agreement.
        </p>
        b. Authorize third-party partners and/or licensees which offer services
        permitting the creation, use and exploitation of so-called “remixes” of
        your Recordings and so-called “user generated content” embodying your
        Recordings, including, without limitation, YouTube, Facebook and
        Instagram (collectively, the “UGC Services”). Without limiting the
        foregoing, you agree that We shall have the right to grant UGC Services
        the rights to synchronize and authorize others to synchronize your
        Recordings (which again, solely for clarification, includes the
        Compositions), with visual images and/or combine excerpts of your
        Recordings with any series of still or moving images, either
        pre-capture, post-capture or as a live stream.
      </div>
    ),
  },
  {
    id: "3",
    header: "3. Communications",
    body: (
      <div>
        By creating an Account on our Service, you agree to subscribe to
        newsletters, marketing or promotional materials and other information we
        may send. However, you may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or by emailing
        at.
      </div>
    ),
  },
  {
    id: "4",
    header: "4. Purchases",
    body: (
      <div>
        Service prices shall be listed on our website at{" "}
        <a href="www.xdistromusic.com" className="text-link">
          www.xdistromusic.com
        </a>
        <p className="my-5">
          If you wish to purchase any product or service made available through
          Service (“Purchase”), you will be asked to supply certain information
          relevant to your Purchase including, without limitation, your credit
          card number, the expiration date of your credit card, your billing
          address, and your shipping information.
        </p>
        You represent and warrant that: (i) you have the legal right to use any
        credit card(s) or other payment method(s) in connection with any
        Purchase; and that (ii) the information you supply to us is true,
        correct and complete.
        <p className="my-5">
          We may employ the use of third party services for the purpose of
          facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy.We reserve the right
          to refuse or cancel your order at any time for reasons including but
          not limited to: product or service availability, error in your order
          or other reasons.
        </p>
        We reserve the right to refuse or cancel your order if fraud or an
        unauthorized or illegal transaction is suspected.
      </div>
    ),
  },
  {
    id: "5",
    header: "5. Payments",
    body: (
      <div>
        a. Payment shall only be made to you if accumulated outstanding earnings
        exceed 50USD. This threshold shall be carried over until such a time as
        it is attained. You agree that your user account and revenue may be
        frozen at our discretion if we believe that materials submitted to us by
        you are illegal, fraudulent or violate these terms of service or any of
        our partners.
        <p className="my-5">
          b. You agree to indemnify and reimburse Us in full via Your royalty
          balance and/or separate payment against any claims, liabilities,
          damages, losses or expenses (including legal fees) suffered or
          incurred by us as a result of your breach of any of the warranties or
          undertakings in this Agreement, or any claims by any third party
          including but not limited to claims for payment of outstanding debts
          for services provided or goods supplied by such third parties.
        </p>
      </div>
    ),
  },
  {
    id: "6",
    header: "6. Subscriptions",
    body: (
      <div>
        Our Service is billed on a subscription basis. You will be billed in
        advance on a recurring and periodic basis (“Billing Cycle”). Billing
        cycles are set on annual basis for all subscription plans
        <p className="my-5">
          At the end of each Billing Cycle, your Subscription will be renewed
          automatically under the exact same conditions unless you cancel it.
          You may cancel your Subscription renewal either through your online
          account management page or by contacting Our customer support team.
        </p>
        A valid payment method, including credit card or PayPal, is required to
        process the payment for your subscription. You shall provide Us with
        accurate and complete billing information including full name, address,
        state, zip code, telephone number, and a valid payment method
        information. By submitting such payment information, you automatically
        authorize Us to charge all Subscription fees incurred through your
        account.
        <p className="my-5">
          Should automatic billing fail to occur for any reason, we will issue
          an electronic invoice indicating that you must proceed manually,
          within a certain deadline date, with the full payment corresponding to
          the billing period as indicated on the invoice.
        </p>
      </div>
    ),
  },
  {
    id: "7",
    header: "7. Fee Changes",
    body: (
      <div>
        In Our sole discretion and at any time, we may modify Subscription fees
        for the Subscriptions. Any Subscription fee change will become effective
        at the end of the then-current Billing Cycle.
        <p className="my-5">
          We will provide you with a reasonable prior notice of any change in
          Subscription fees to give you an opportunity to terminate your
          Subscription before such change becomes effective.
        </p>
        Your continued use of Service after Subscription fee change comes into
        effect constitutes your agreement to pay the modified Subscription fee
        amount.
      </div>
    ),
  },
  {
    id: "8",
    header: "8. Refunds",
    body: (
      <div>
        Except when required by law, paid Subscription fees are non-refundable.
      </div>
    ),
  },
  {
    id: "9",
    header: "9. Content",
    body: (
      <div>
        By uploading Content on or through Service, You represent and warrant
        that: (i) Content is yours (you own it) and/or you have the right to use
        it and the right to grant us the rights and license as provided in these
        Terms, and (ii) that the posting of your Content on or through Service
        does not violate the privacy rights, publicity rights, copyrights,
        contract rights or any other rights of any person or entity. We reserve
        the right to terminate the account of anyone found to be infringing on a
        copyright. You retain any and all of your rights to any Content you
        submit, post or upload on or through Service and you are responsible for
        protecting those rights. We take no responsibility and assume no
        liability for Content you or any third party posts on or through
        Service. However, by posting Content using Service you grant us the
        right and license to use, modify, publicly perform, publicly display,
        reproduce, and distribute such Content on and through Service. You agree
        that this license includes the right for us to make your Content
        available to other users of Service, who may also use your Content
        subject to these Terms.
        <p className="my-5">
          We have the right but not the obligation to monitor and edit all
          Content provided by users.
        </p>
        In addition, Content found on or through this Service is the property of
        XDistro Music or used with permission. You may not distribute, modify,
        transmit, reuse, download, repost, copy, or use said Content, whether in
        whole or in part, for commercial purposes or for personal gain, without
        express advance written permission from us.
      </div>
    ),
  },
  {
    id: "10",
    header: "10. Prohibited Uses",
    body: (
      <div>
        You may use Service only for lawful purposes and in accordance with
        Terms. You agree not to use Service:
        <p>
          <ol className="list-[lower-alpha] px-[14px] items-start">
            <li>
              In any way that violates any applicable national or international
              law or regulation.
            </li>
            <li className="my-2">
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content or otherwise.
            </li>
            <li className="my-2">
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail”, “chain letter,”
              “spam,” or any other similar solicitation.
            </li>
            <li className="my-2">
              To impersonate or attempt to impersonate Company, a Company
              employee, another user, or any other person or entity.
            </li>
            <li className="my-2">
              In any way that infringes upon the rights of others, or in any way
              is illegal, threatening, fraudulent, or harmful, or in connection
              with any unlawful, illegal, fraudulent, or harmful purpose or
              activity.
            </li>
            <li className="my-2">
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of Service, or which, as determined by us, may
              harm or offend Company or users of Service or expose them to
              liability.
            </li>
          </ol>
        </p>
        <p className="my-5">Additionally, you agree not to:</p>
        <p>
          <ol className="list-[lower-alpha] px-[14px]">
            <li>
              Use Service in any manner that could disable, overburden, damage,
              or impair Service or interfere with any other party’s use of
              Service, including their ability to engage in real time activities
              through Service.
            </li>
            <li className="my-2">
              Use any robot, spider, or other automatic device, process, or
              means to access Service for any purpose, including monitoring or
              copying any of the material on Service.
            </li>
            <li className="my-2">
              Use any manual process to monitor or copy any of the material on
              Service or for any other unauthorized purpose without our prior
              written consent.
            </li>
            <li className="my-2">
              Use any device, software, or routine that interferes with the
              proper working of Service.
            </li>
            <li className="my-2">
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful.
            </li>
            <li className="my-2">
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of Service, the server on which Service is
              stored, or any server, computer, or database connected to Service.
            </li>
            <li className="my-2">
              Attack Service via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li className="my-2">
              Take any action that may damage or falsify Company rating.
            </li>
            <li className="my-2">
              Otherwise attempt to interfere with the proper working of Service
            </li>
          </ol>
        </p>
      </div>
    ),
  },
  {
    id: "11",
    header: "11. Age Restriction",
    body: (
      <div>
        Service is for use, only by individuals of at least eighteen (18) years
        of age.
        <p>
          By accessing or using Service, you warrant and represent that you are
          at least eighteen (18) years of age and with the full authority,
          right, and capacity to enter into this agreement and abide by all of
          the terms and conditions of Service.
        </p>
        <p>
          If you are not at least eighteen (18) years old, you are prohibited
          from both the access and usage of Service.
        </p>
      </div>
    ),
  },
  {
    id: "12",
    header: "12. Accounts",
    body: (
      <div>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        Service.
        <p className="my-5">
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
        <p className="my-5">
          We reserve the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in our sole discretion.
        </p>
      </div>
    ),
  },
  {
    id: "13",
    header: "13. Copyright Policy",
    body: (
      <div>
        We respect the intellectual property rights of others. It is our policy
        to respond to any claim that Content posted on Service infringes on the
        copyright or other intellectual property rights (“Infringement”) of any
        person or entity.
        <p className="my-5">
          If you are a copyright owner, or authorized on behalf of one, and you
          believe that the copyrighted work has been copied in a way that
          constitutes copyright infringement, please submit your claim via email
          to support@xdistromusic.com, with the subject line: “Copyright
          Infringement” and include in your claim a detailed description of the
          alleged Infringement. You may be held accountable for damages
          (including costs and attorneys' fees) for misrepresentation or
          bad-faith claims on the infringement of any Content found on and/or
          through Service on your copyright.
        </p>
      </div>
    ),
  },
  {
    id: "14",
    header: "14. Disclaimer Of Warranty",
    body: (
      <div>
        THIS SERVICE IS PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THIS SERVICE, THE CONTENTS, AND ANY SERVICES OR ITEMS
        OBTAINED FROM US IS AT YOUR SOLE RISK.NEITHER COMPANY NOR ANY PERSON
        ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
        RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
        OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
        COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT
        THE SERVICE, THEIR CONTENTS, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
        THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
        THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
        MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
        THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE
        WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS
        ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </div>
    ),
  },
];

export default data;
