import React from "react";
interface Props {
  color?: string;
  hover?: string;
  children?: React.ReactNode;
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean;
  textColor?: string;
  type?: "button" | "submit" | "reset" | undefined;
}

const Button: React.FC<Props> = ({ color, hover, children, className, onClick, disabled, textColor, type }) => {
  return (
    <button
      type={type || "button"}
      disabled={disabled}
      onClick={onClick}
      className={`${color || "bg-xd_yellow"} ${hover || "hover:text-xd_yellow hover:bg-black"
        } px-[30px] py-[10px] font-sans  ${textColor || "text-xd_black"} rounded box-border ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
