import React, { useContext, useEffect, useState } from "react";
import { ReleaseType } from "../../interfaces/release";
import { IGenre } from "../../interfaces/genre";
import sendRequest from "../axios";
import IRelease from "../../interfaces/release";

type UpdateReleaseFunction = (releaseType: ReleaseType, data: any) => void;
type INewRelease = {
  "release-details": FormData;
  "release-track-list": FormData;
  "release-royalty-splits": FormData;
  "release-distribution": FormData;
};

export const CreateReleaseContext = React.createContext(
  {} as {
    newRelease: INewRelease;
    updateNewRelease: UpdateReleaseFunction;
    genres: IGenre[];
    release: IRelease;
    setRelease: React.Dispatch<React.SetStateAction<IRelease>>;
  }
);

export const useCreateReleaseContext = () => useContext(CreateReleaseContext);

interface Props {
  children: React.ReactNode;
}

export function CreateReleaseProvider({ children }: Props) {
  const [genres, setGenres]: [
    IGenre[],
    React.Dispatch<React.SetStateAction<IGenre[]>>
  ] = React.useState([] as IGenre[]);

  const [release, setRelease]: [IRelease, React.Dispatch<React.SetStateAction<IRelease>>] = useState({} as IRelease);
  const [newRelease, setNewRelease]: [
    INewRelease,
    React.Dispatch<React.SetStateAction<INewRelease>>
  ] = useState({
    "release-details": new FormData(),
    "release-track-list": new FormData(),
    "release-royalty-splits": new FormData(),
    "release-distribution": new FormData(),
  });

  function getGenres() {
    sendRequest("get", "/api/v1/genres", {})
      .then((res) => {
        // console.log({ genres: res });
        setGenres(res.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  useEffect(() => {
    getGenres();
  }, []);

  function updateNewRelease(releaseType: ReleaseType, data: FormData) {
    setNewRelease({
      ...newRelease,
      [releaseType]: data,
    });
  }

  return (
    <CreateReleaseContext.Provider
      value={{
        newRelease: newRelease,
        updateNewRelease: updateNewRelease,
        genres,
        release,
        setRelease,
      }}
    >
      {children}
    </CreateReleaseContext.Provider>
  );
}
