import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as YellowLogo } from "../assets/logo-yellow.svg";
import Button from "../components/Button/Button";

function ErrorNopage() {
  return (
    <div className="w-full bg-xd_bleach flex flex-col">
      <nav className="flex flex-row items-center top-0 h-28 py-5 px-10 justify-between">
        <div className="w-[90px] h-[90px]">
          <YellowLogo />
        </div>
        <div className="flex scale-50 md:scale-100 space-x-10 items-center">
          <Link className="text-xd_black py-2 px-6 rounded-md transition-all ease-in duration-300 hover:bg-xd_lightblack hover:text-xd_yellow border-2" to="/">Home</Link>
          <Link className="text-xd_black py-2 px-6 rounded-md transition-all ease-in duration-200 hover:bg-xd_lightblack hover:text-xd_yellow border-2" to="/faq">FAQ</Link>
          <Link className="lg:pl-10" to="/signup"><Button> Get Started </Button></Link>
        </div>
      </nav>
      <hr className="mx-10" />
      <section className="grid md:grid-cols-2 h-full px-10 pt-16">
        <div className="text-xd_lightblack grid-row-3">
          <p className="font-extra-bold text-5xl pb-3">Oops! Page Not Found</p>
          <p className="font-extra-bold text-7xl">Error</p>
          <p className="font-extra-bold text-[40vh] -mt-20">404</p>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 512" id="disconnected">
            <path fill="#dbe8ec" d="M593.90418,212.85529V194.66413a13.07945,13.07945,0,0,0-13.07944-13.07946l-46.166,0a13.07946,13.07946,0,0,1-13.07945-13.07946l0-18.19116a13.07945,13.07945,0,0,1,13.07946-13.07945h1.45764a13.07944,13.07944,0,0,0,13.07945-13.07944l0-18.19117a13.07945,13.07945,0,0,0-13.07945-13.07945l-391.90714-.00014a13.07946,13.07946,0,0,0-13.07946,13.07945V124.155a13.07944,13.07944,0,0,0,13.07944,13.07946h0a13.07945,13.07945,0,0,1,13.07945,13.07945l0,18.19117a13.07944,13.07944,0,0,1-13.07945,13.07944l-50.57941,0A13.07945,13.07945,0,0,0,80.55038,194.664l0,18.19116a13.07945,13.07945,0,0,0,13.07945,13.07945l19.84658,0A13.07945,13.07945,0,0,1,126.55585,239.014v18.19124a13.07946,13.07946,0,0,1-13.07946,13.07945h-.048A13.07947,13.07947,0,0,0,100.349,283.36418v18.19108a13.07944,13.07944,0,0,0,13.07944,13.07945h2.87139a13.07947,13.07947,0,0,1,13.07945,13.07946l0,18.19125a13.07944,13.07944,0,0,1-13.07945,13.07944l-47.12451,0A13.07945,13.07945,0,0,0,56.09582,372.0643v18.19116a13.07944,13.07944,0,0,0,13.07944,13.07945l473.963.00017a13.07947,13.07947,0,0,0,13.07946-13.07945V372.06447A13.07945,13.07945,0,0,0,543.13829,358.985H531.79777a13.07947,13.07947,0,0,1-13.07945-13.07946l0-18.19125a13.07945,13.07945,0,0,1,13.07945-13.07945l26.73986,0a13.07944,13.07944,0,0,0,13.07946-13.07944V283.36434a13.07945,13.07945,0,0,0-13.07944-13.07946H550.426a13.07945,13.07945,0,0,1-13.07945-13.07946l0-18.19124a13.07945,13.07945,0,0,1,13.07946-13.07945l30.39867,0A13.07946,13.07946,0,0,0,593.90418,212.85529Z"></path>
            <rect width="73.197" height="34.339" x="193.386" y="227.282" fill="#b9d4db" rx="2" transform="rotate(180 229.984 244.452)"></rect>
            <rect width="73.197" height="34.339" x="229.984" y="186.753" fill="#b9d4db" rx="2" transform="rotate(180 266.583 203.923)"></rect>
            <rect width="73.197" height="34.339" x="150.597" y="186.753" fill="#b9d4db" rx="2" transform="rotate(180 187.196 203.923)"></rect>
            <rect width="73.197" height="34.339" x="399.92" y="285.817" fill="#b9d4db" rx="2"></rect>
            <rect width="73.197" height="34.339" x="363.322" y="326.346" fill="#b9d4db" rx="2"></rect>
            <rect width="73.197" height="34.339" x="442.709" y="326.346" fill="#b9d4db" rx="2"></rect>
            <path fill="none" stroke="#3086a3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4" d="M338.83344,378.83206V391.658a18.677,18.677,0,0,1-18.677,18.677H257.41074a18.677,18.677,0,0,0-18.677,18.677v0a18.677,18.677,0,0,1-18.677,18.677H171.597a18.677,18.677,0,0,1-18.677-18.677V395.90913a18.677,18.677,0,0,0-18.677-18.677H76.09585"></path>
            <rect width="200.88" height="195.12" x="238.734" y="64.311" fill="#f9ae2b" rx="7"></rect>
            <path fill="#409cb5" d="M383.07343,258.59216h-88.48a4,4,0,0,0-4,4v39.51978a4,4,0,0,0,4,4h13.47644a4,4,0,0,1,4,4V346.0321a4,4,0,0,0,4,4h45.527a4,4,0,0,0,4-4V310.11194a4,4,0,0,1,4-4h13.47657a4,4,0,0,0,4-4V262.59216A4,4,0,0,0,383.07343,258.59216Z"></path>
            <rect width="80.28" height="14.4" x="298.693" y="335.632" fill="#f9ae2b" rx="7.2"></rect>
            <path fill="#3086a3" d="M311.65344,350.0321h54.36a0,0,0,0,1,0,0v7.47a6.93,6.93,0,0,1-6.93,6.93H318.58347a6.93,6.93,0,0,1-6.93-6.93v-7.47A0,0,0,0,1,311.65344,350.0321Z"></path>
            <path fill="#409cb5" d="M322.50992,364.43207h32.647a0,0,0,0,1,0,0v12.47648a16.3235,16.3235,0,0,1-16.3235,16.3235h0a16.3235,16.3235,0,0,1-16.3235-16.3235V364.43207A0,0,0,0,1,322.50992,364.43207Z"></path>
            <line x1="361.833" x2="361.833" y1="258.592" y2="215.752" fill="none" stroke="#3086a3" strokeMiterlimit="10" strokeWidth="10"></line>
            <line x1="315.833" x2="315.833" y1="258.592" y2="215.752" fill="none" stroke="#3086a3" strokeMiterlimit="10" strokeWidth="10"></line>
            <circle cx="339.174" cy="161.871" r="47.52" fill="#409cb5"></circle>
            <path fill="#3086a3" d="M302.56671,173.92324a47.50609,47.50609,0,0,1,76.7716-37.4353,47.513,47.513,0,1,0-69.41614,62.81836A47.27983,47.27983,0,0,1,302.56671,173.92324Z"></path>
            <circle cx="316.174" cy="165.109" r="8.28" fill="#3086a3"></circle>
            <circle cx="362.174" cy="165.109" r="8.28" fill="#3086a3"></circle>
            <line x1="463.609" x2="495.911" y1="131.272" y2="131.272" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="463.609" x2="495.911" y1="140.582" y2="140.582" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="463.609" x2="495.911" y1="149.893" y2="149.893" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="179.585" x2="211.888" y1="121.961" y2="121.961" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="179.585" x2="211.888" y1="131.272" y2="131.272" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="179.585" x2="211.888" y1="140.582" y2="140.582" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="220.412" x2="252.715" y1="310.237" y2="310.237" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="220.412" x2="252.715" y1="319.548" y2="319.548" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
            <line x1="220.412" x2="252.715" y1="328.858" y2="328.858" fill="none" stroke="#b9d4db" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"></line>
          </svg>
        </div>
      </section>
    </div>
  )
}

export default ErrorNopage;
