import React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import Header from "../../components/Header/Header";
import SplitsTable from "../../components/SplitsTable/SplitsTable";
import TermsAndPolicies from "../../components/TermsAndPolicies/TermsAndPolicies";

function RoyaltySplits() {
  return (
      <div>
      <div>
        <Header children="Royalty Splits" />
      </div>
      <div className="my-20">
        <SplitsTable />
      </div>
      <div className="my-10">
        <TermsAndPolicies />
      </div>
      </div>
  );
}

export default RoyaltySplits;
