import { Link } from "react-router-dom";
import WhiteLogo from "../Icons/LandingLogo";
import ArrowScrollUpIcon from "../Icons/ArrowScrollUpIcon";

const Footer = () => {
  const ScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  return (
    <footer className="bg-xd_black text-white py-14">
      <div className="md:flex gap-10 items-center">
        <h1 className="mx-10 h-[150px] w-[150px] hover:animate-spin"><WhiteLogo /></h1>
        <section className="container mx-auto lg:px-0 px-10">
          <div className="flex lg:flex-row gap-10 flex-col">
            <div className="lg:basis-1/3 basis-0">
              <h1 className="cursor-default">Resources</h1>
              <div className="inline-block mr-10">
                <Link to="/faq" className="text-gray-400 my-3 block transition ease-in duration-150 hover:text-gray-100">
                  FAQs
                </Link>
                <Link to="/privacypolicy" className="text-gray-400 my-3 block transition ease-in duration-150 hover:text-gray-100">
                  Privacy Policy
                </Link>
              </div>
              <div className="inline-block">
                <Link to="" className="text-gray-400 my-3 block transition ease-in duration-150 hover:text-gray-100">
                  Contact Us
                </Link>
                <Link to="/termsofuse" className="text-gray-400 my-3 block transition ease-in duration-150 hover:text-gray-100">
                  Terms and Conditions
                </Link>
              </div>
            </div>
            <div className="lg:basis-1/4">
              <h1 className="cursor-default">Socials</h1>
              <div className="inline-block mr-10">
                <a target={"_blank"} href="https://www.instagram.com/xdistromusic" className="text-gray-400 my-3 block transition ease-in duration-150 hover:underline hover:underline-offset-1 hover:decoration-xd_yellow hover:text-xd_yellow">
                  Instagram
                </a>
                <a target={"_blank"} href="https://youtube.com/@xdistromusic" className="text-gray-400 my-3 block transition ease-in duration-150 hover:underline hover:underline-offset-1 hover:decoration-xd_yellow hover:text-xd_yellow">
                  Youtube
                </a>
              </div>
              <div className="inline-block">
                <a  target={"_blank"} href="https://www.facebook.com/xdistromusic" className="text-gray-400 my-3 block transition ease-in duration-150 hover:underline hover:underline-offset-1 hover:decoration-xd_yellow hover:text-xd_yellow">
                  Facebook
                </a>
                <a target={"_blank"} href="https://x.com/xdistromusic" className="text-gray-400 my-3 block transition ease-in duration-150 hover:underline hover:underline-offset-1 hover:decoration-xd_yellow hover:text-xd_yellow">
                  Twitter
                </a>
              </div>
            </div>
            <div className="lg:basis-1/3">
              <h1 className="invisible">1</h1>
              <div className="inline-block ">
                <div className="text-gray-400 my-3 block transition ease-in duration-150 hover:text-gray-100">
                  10 Lombard Street
                </div>
                <div className="text-gray-400 my-3 block transition ease-in duration-150 hover:text-gray-100">
                  Birmingham, United Kingdom.
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="mr-10 w-[47px] h-[47px] md:visible transition-all ease-in duration-150 md:hover:-translate-y-2 md:hover:scale-105 invisible cursor-pointer" onClick={ScrollToTop}>
          <ArrowScrollUpIcon />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
