import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sendRequest from "../components/axios";
import Button from "../components/Button/Button";
import Input from "../components/Input/Index";
import XDSwitch from "../components/Switch/Index";
import AuthLayout from "./AuthLayout";
import { PuffLoader } from "react-spinners";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "../components/Select/Select";
import countryList from 'react-select-country-list'
import ReactGA from "react-ga4";

function Signup() {
  document.title = "Signup - XDistro Music";
  const [apiMessage, setApiMessage] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [mycountry, setMyCountry] = useState<string>("");
  useEffect(() => {
    setCountries([{ value: "", label: "Select Country" }, ...countryList().getData()]);
    setMyCountry(Intl.DateTimeFormat().resolvedOptions().locale.split("-")[1]);
  }, [])

  const { handleChange, handleSubmit, getFieldProps, touched, errors, values } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      country: mycountry,
      terms: false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      username: Yup.string().required("Username is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      password: Yup.string().min(8, "Password must be a minimum of 8 digits").required("Password is required"),
      confirm_password: Yup.string().oneOf([Yup.ref("password")], "Passwords must match").required("Cannot be empty"),
      country: Yup.string().required("Country is required"),
      terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setApiMessage("");
      setApiError("");
      sendRequest("POST", "/api/v1/user", values)
        .then((res) => {
          console.log(res.data);
          setApiMessage(res.data.message);
          if (process.env.REACT_APP_GA4_ID) {
            ReactGA.event({
              category: "User",
              action: "Signup",
              label: "successful", // optional
              // value: , // optional, must be a number
              // nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            setApiError(err.response.data?.errors);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <AuthLayout>
      <h1 className="text-3xl md:text-big">Create an account</h1>
      <p className="text-xd_gray font-normal text-sm my-2">
        Already have an account?{" "}
        <Link to="/login" className="text-blue-600">
          login
        </Link>
      </p>
      <div className="space-y-3">
        {apiMessage ? (
          <p className="text-green-700 font-normal text-sm">{apiMessage}</p>
        ) : (
          ""
        )}
        {apiError ? (
          <p className="text-xd_red font-normal text-sm">{apiError}</p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} className="space-y-3">
          <div className="flex justify-between gap-2">
            <Input
              className="w-full"
              id="first_name"
              placeholder="First name"
              type="text"
              label="First name"
              error={touched.first_name && errors.first_name ? errors.first_name : ""}
              {...getFieldProps("first_name")}
            />
            <Input
              className="w-full"
              id="last_name"
              placeholder="Last name"
              type="text"
              label="Last name"
              error={touched.last_name && errors.last_name ? errors.last_name : ""}
              {...getFieldProps("last_name")}
            />
          </div>
          <div className="">
            <Input
              className=""
              id="username"
              placeholder="username"
              type="text"
              label="Username"
              error={touched.username && errors.username ? errors.username : ""}
              {...getFieldProps("username")}
            />
          </div>
          <div className="">
            <Input
              className=""
              id="email"
              placeholder="Email"
              type="email"
              label="Email"
              error={touched.email && errors.email ? errors.email : ""}
              {...getFieldProps("email")}
            />
          </div>
          <div className="">
            <Input
              className=""
              id="password"
              placeholder="Password"
              type="password"
              label="Password"
              error={touched.password && errors.password ? errors.password : ""}
              {...getFieldProps("password")}
            />
          </div>
          <div className="">
            <Input
              className=""
              id="confirm_password"
              placeholder="Confirm password"
              type="password"
              label="Confirm password"
              error={touched.confirm_password && errors.confirm_password ? errors.confirm_password : ""}
              {...getFieldProps("confirm_password")}
            />
          </div>
          <Select
            {...getFieldProps("country")}
            options={countries}
            label="Country"
            //onChange={handleChange}
            //name="country"
            //defaultValue={values.country}
            error={touched.country && errors.country ? errors.country : ""}
          />
          <div className="flex justify-center text-sm space-x-2 font-light py-4 ">
            <XDSwitch onChange={(e) => {
              //console.log(e);
              handleChange({
                target: {
                  name: "terms",
                  value: e
                }
              })
            }} />
            <div>
              I have read and agreed to the{" "}
              <Link to="/termsofuse" className=" text-blue-600">
                Terms and Conditions
              </Link>
              ,
              <Link to="/privacypolicy" className=" text-blue-600">
                Privacy Policy
              </Link>{" "}
            </div>
          </div>

          {touched.terms && errors.terms ? (
            <p className="text-xd_red font-normal text-sm">{errors.terms}</p>
          ) : (
            ""
          )}
          <div className="">
            <Button disabled={loading} type="submit" className="w-full">
              {loading ? <PuffLoader className="h-4 w-4" size={30} color="white" /> : "Create account "}
            </Button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default Signup;
