import React from 'react'
import { Link } from 'react-router-dom'
import RedirectIcon from '../Icons/RedirectIcon'

function TermsAndPolicies
() {
  return (
    <div className='flex justify-end gap-5 font-semibold'>
      <div>
        <Link to={'/termsofuse'} className="flex gap-2">
          <div>Terms of Use</div>
          <div className='w-[15px]'><RedirectIcon /></div>
        </Link>
      </div>
      <div>
        <Link to={'/privacypolicy'} className="flex gap-2">
          <div>Privacy Policy</div>
          <div className='w-[15px]'><RedirectIcon /></div>
        </Link>
      </div>
    </div>
  )
}

export default TermsAndPolicies
