import { useFormik } from "formik";
import { useState } from "react";
import sendRequest from "../axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Input from "../Input/Index";
import { PuffLoader } from "react-spinners";
import Button from "../Button/Button";
import ErrorTypography from "../ErrorTypography";

function BankTransferPayment() {
  const [loading, setLoading] = useState(false);
  
  // Change request url
  var addAccountURL = `/api/v1/user/bankaccount`;

  const PaymentFormSchema = Yup.object().shape({
    full_name: Yup.string().required("full name is required"),
    account_number: Yup.string().required("account number is required"),
    sort_code: Yup.string(),//.required("Required"),
    swift_code: Yup.string(),//.required("Required"),
    bank_name: Yup.string().required("bank name is required"),
    iban: Yup.string(),//.required("Required"),
    address: Yup.string(),//.required("Required"),
  });

  const { handleSubmit, touched, errors, values, handleChange } = useFormik({
    initialValues: {
      full_name: "",
      account_number: "",
      sort_code: "",
      swift_code: "",
      bank_name: "",
      iban: "",
      address: "",
    },
    validationSchema: PaymentFormSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await sendRequest("PUT", addAccountURL, values);
        //console.log(response);
        if (response.status < 300) {
          toast.success(`${response.data.message}`);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <div className="px-32 p-5">
      <div className="font-medium text-18 leding-[22.56px]">
        Enter Payout Details
      </div>
      <div className="flex justify-center flex-col px-28 mt-5">
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <div>
            <Input
              className=""
              id="full_name"
              placeholder={
                values.full_name === "" ? "John Doe" : values.full_name
              }
              type="text"
              label="Full Name"
            />
            { errors.full_name && (
              <ErrorTypography error={errors.full_name} />
            )}
          </div>
          <div className="my-5">
            <Input
              className=""
              id="account_number"
              placeholder={
                values.account_number === ""
                  ? "0123456789"
                  : values.account_number
              }
              type="text"
              label="Account Number"
            />
            { errors.account_number && (
              <ErrorTypography error={errors.account_number} />
            )}
          </div>
          <div>
            <Input
              className=""
              id="sort_code"
              name="sort_code"
              placeholder={
                values.sort_code === "" ? "01234567" : values.sort_code
              }
              type="text"
              label="Sort Code"
            />
            { errors.sort_code && (
              <ErrorTypography error={errors.sort_code} />
            )}
          </div>
          <div className="my-5">
            <Input
              className=""
              id="swift_code"
              placeholder={
                values.swift_code === "" ? "AAAABBCC123" : values.swift_code
              }
              type="text"
              label="Swift Code"
            />
            {errors.swift_code && (
              <ErrorTypography error={errors.swift_code} />
            )}
          </div>
          <div>
            <Input
              className=""
              id="bank_name"
              placeholder={
                values.bank_name === ""
                  ? "Community Federal Savings Bank"
                  : values.bank_name
              }
              type="text"
              label="Bank Name"
            />
            { errors.bank_name && (
              <ErrorTypography error={errors.bank_name} />
            )}
          </div>
          <div className="my-5">
            <Input
              className=""
              id="iban"
              placeholder={values.iban === "" ? "GB69740078888" : values.iban}
              type="text"
              label="Iban"
            />
            { errors.iban && (
              <ErrorTypography error={errors.iban} />
            )}
          </div>
          <div>
            <Input
              className=""
              id="address"
              placeholder={
                values.address === ""
                  ? "89-36 Westminster Avenue"
                  : values.address
              }
              type="text"
              label="Address"
            />
            {errors.address && (
              <ErrorTypography error={errors.address} />
            )}
          </div>
          <div className="my-10 w-full">
            <Button disabled={loading} type="submit" className="w-full">
              {loading ? (
                <PuffLoader className="h-4 w-4" size={25} color="white" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BankTransferPayment;
