import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import BackArrowIcon from "../../components/Icons/BackArrowIcon";
import TermsAndPolicies from "../../components/TermsAndPolicies/TermsAndPolicies";
import { IContributor, Track } from "../../interfaces/release";
import { useNavigate, useParams } from "react-router-dom";
import { useDashboardContext } from "../../components/Dashboard/DashboardContext";
import Table from "../../components/Table/Table";
import SpiltsAmount from "../../components/ArtistCut/SpiltsAmount";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Index";
import { CancelIcon } from "../../components/Icons/Icons";
import sendRequest from "../../components/axios";
import XPieChart from "../../components/PieChart/PieChart";

function GetArtistTableData(
  data: IContributor[]
): { [index: string]: React.ReactNode }[] {
  return data.map((d) => {
    return {
      split: <SpiltsAmount split={d.split} />,
      name: d.name,
      email: d.email,
      role: d.role,
    };
  });
}

function TrackSplit() {
  const params = useParams<{ track_id: string }>();
  const { tracks } = useDashboardContext();
  const [track, setTrack] = React.useState<Track>();
  const [editSplits, setEditSplits] = useState(false);
  const [splits, setSplits] = useState<IContributor[]>([]);
  const [apiError, setApiError] = useState<string>();
  const [apiMessage, setApiMessage] = useState<string>();
  const [mySplit, setMySplit] = useState<number>(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    // fetch track
    setTrack(tracks?.find((t) => t.id === params.track_id));
  }, [tracks]);

  useEffect(() => {
    if (track && track.release && track.release.status !== "ready") {
      navigate("/dashboard/royalty-splits");
    }
    // fetch splits
    setMySplit(
      track?.contributors.find(
        (c) => c.artist_id === track.release?.release_artist_id
      )?.split || 0
    );
  }, [track]);

  useEffect(() => {
    if (editSplits) {
      // fetch splits
      setSplits(track?.contributors || []);
    }
  }, [editSplits]);

  const columns = [
    { title: "Split", dataIndex: "split", key: "1" },
    { title: "Name", dataIndex: "name", key: "2" },
    { title: "Email", dataIndex: "email", key: "3" },
    { title: "Role", dataIndex: "role", key: "4" },
  ];

  function updateSplits() {
    console.log(splits);
    sendRequest("PUT", `/api/v1/tracks/${params.track_id}/contributors`, splits)
      .then((res) => {
        setApiMessage(res.data.message);
        setApiError("");
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          setApiError(err.response.data.errors);
        }
      });
  }

  return (
    <div>
      <div>
        <Header
          children={"Royalty Splits"}
          icon={
            <div className="h-[32px]">
              <Link to={"/dashboard/royalty-splits"}>
                <BackArrowIcon />
              </Link>
            </div>
          }
        />
      </div>
      <div className="flex justify-center my-20 bg-white rounded-[10px]">
        <div className="p-4 md:p-7 w-full">
          <div>
            <div className="flex gap-3 items-center justify-between">
              <div className="flex gap-3 items-center">
                <div className="max-w-[7rem]">
                  <img
                    className="rounded-lg"
                    src={track?.release?.cover_art}
                    alt=""
                  />
                </div>
                <div className="space-y-3">
                  <p className="font-bold text-2xl">{track?.title}</p>
                  <div className="flex gap-5">
                    <div>{track?.release_artist}</div>
                    <div className="flex gap-3">
                      My Split: {mySplit}%
                      <div className="h-[23px]">
                        <XPieChart split={mySplit} startAngle={90} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div>
                  <Button
                    onClick={() => {
                      setEditSplits(editSplits ? false : true);
                    }}>
                    {editSplits ? "Cancel " : ""}Edit Split
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 overflow-x-scroll scroll">
            <div className="inline-block min-w-full">
              {editSplits ? (
                <div>
                  <form>
                    {apiError ? (
                      <p className="text-xd_red font-normal text-sm">
                        {apiError}
                      </p>
                    ) : (
                      ""
                    )}
                    {apiMessage ? (
                      <p className="text-xd_green font-normal text-sm">
                        {apiMessage}
                      </p>
                    ) : (
                      ""
                    )}
                    {splits?.map((c, i) => (
                      <div
                        key={i}
                        className="flex justify-between items-center gap-5">
                        <Input
                          label="Split %"
                          className="w-full basis-2/12"
                          type="number"
                          min="0"
                          name="split"
                          placeholder={""}
                          value={c.split}
                          onChange={(e) => {
                            const newSplits = [...splits];
                            newSplits[i].split = parseInt(e.target.value);
                            setSplits(newSplits);
                          }}
                        />
                        <Input
                          label="Name"
                          className="w-full basis-5/12"
                          type="text"
                          name="name"
                          placeholder={""}
                          value={c.name}
                          onChange={(e) => {
                            const newSplits = [...splits];
                            newSplits[i].name = e.target.value;
                            setSplits(newSplits);
                          }}
                        />
                        <Input
                          label="Email"
                          className="w-full basis-5/12"
                          type="email"
                          name="email"
                          placeholder={""}
                          value={c.email}
                          onChange={(e) => {
                            const newSplits = [...splits];
                            newSplits[i].email = e.target.value;
                            setSplits(newSplits);
                          }}
                        />
                        <CancelIcon />
                      </div>
                    ))}
                    <div className="my-5">
                      <Button
                        type="button"
                        color="bg-transparent"
                        onClick={() => {
                          setSplits([
                            ...splits,
                            {
                              id: "",
                              full_name: "",
                              split: 0,
                              name: "",
                              email: "",
                              role: "",
                              artist_id: "",
                            },
                          ]);
                        }}
                        className="w-full mt-2 border border-xd_yellow">
                        Add New Split
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          updateSplits();
                        }}
                        className="w-full mt-2">
                        Save Changes
                      </Button>
                    </div>
                  </form>
                </div>
              ) : (
                <Table
                  data={GetArtistTableData(track?.contributors || [])}
                  columns={columns}
                  border="border-none"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-10">
        <TermsAndPolicies />
      </div>
    </div>
  );
}

export default TrackSplit;
