import React, { useState } from "react";
import DashSideBar from "../DashSideBar/DashSideBar";
import DashboardProvider from "./DashboardContext";
import { CreateReleaseProvider } from "../Release/CreateReleaseContext";

interface Props {
  children?: React.ReactNode;
}

function Dashboard({ children }: Props) {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <DashboardProvider>
      <CreateReleaseProvider>
        <div className="h-screen overflow-auto items-center">
          <DashSideBar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
          <div className="md:ml-[260px] px-3 md:px-8 py-6">{children}</div>
        </div>
      </CreateReleaseProvider>
    </DashboardProvider>
  );
}

export default Dashboard;
