const text = [
  {
    id: 1,
    header: <h1>What is XDistro Music?</h1>,
    body: (
      <div className="leading-6">
        XDistro Music is a digital distributions service that musicians and
        labels use to upload music to online stores & streaming services such as
        Apple Music, Spotify, Deezer, Tidal and more.
        <p className="my-2">
          XDistro Music collects earnings in royalties and sends 100% of these
          payments to artists and labels. XDistro Music will however minus bank
          processing fees and taxes where applicable.
        </p>
      </div>
    ),
  },

  {
    id: 2,
    header: <h1>How Much Does XDistro Music Cost?</h1>,
    body: (
      <div className="leading-6">
        XDistro Music offers a range of plans to fit every budget.
        <p className="my-2">
          (a). <span className="font-bold">The "Artist" plan:</span> This plan
          accommodates a single artist, and costs $13.99/year. <br />
          (b). <span className="font-bold">The "PRO" plan:</span> This plan
          accommodates up to five (5) artists, and costs $49.99/year.
          <br />
          (c). <span className="font-bold">The "LABEL" Plan:</span> This plan is
          made for labels or managements with up to twenty (20) artists, and
          costs just $149.99
        </p>
      </div>
    ),
  },

  {
    id: 3,
    header: <h1>Where will my music be distributed?</h1>,
    body: (
      <div className="leading-6">
        Your music will be everywhere.
        <p className="my-2">
          With XDistro Music, you can release music on all the services such as
          Spotify, Apple Music, TikTok, Tidal, YouTube, Amazon Music, Google
          Play, Pandora, Sirius XM, Tencent, iHeart Radio, Napster, Boomplay and
          more.
        </p>
      </div>
    ),
  },

  {
    id: 4,
    header: (
      <h1>
        How Long Will It Take for My Music to Be Available in Streaming
        Services?
      </h1>
    ),
    body: (
      <div className="leading-6">
        Upon approval, it takes 1-7 days for your release to go live in stores
        depending on the release date you selected. Once we go through your
        release and every detail is on the clear, we will send your music to
        stores immediately.
        <p className="my-2">
          NOTE: Please be sure to submit your release as early as 4weeks prior
          to your release date to avoid unnecessary delays that may be beyond
          our control.
        </p>
      </div>
    ),
  },

  {
    id: 5,
    header: <h1>What countries will my music be available in?</h1>,
    body: (
      <div className="leading-6">Your music will be released worldwide.</div>
    ),
  },

  {
    id: 6,
    header: <h1>Can I upload cover songs?</h1>,
    body: (
      <div className="leading-6">
        Absolutely! You can upload cover songs however; some cover songs may be
        licensed. Please check to be sure all samples on the cover are cleared
        before uploading.
      </div>
    ),
  },

  {
    id: 7,
    header: <h1>Who owns my music?</h1>,
    body: (
      <div className="leading-6">
        You own 100% of your music. XDistro Music does not take any ownership or
        intellectual property rights of any artist.
      </div>
    ),
  },

  {
    id: 8,
    header: (
      <h1>What is the minimum age requirement to use XDistro Music service?</h1>
    ),
    body: (
      <div className="leading-6">
        You must be 18 years of age or older to use XDistro Music service. If
        you are younger than that, please have an adult signup on your behalf.
      </div>
    ),
  },

  {
    id: 9,
    header: <h1>What are the terms of your service?</h1>,
    body: (
      <div className="leading-6">
        Please see our Terms of Service:
        <ol className="list-disc my-3 ml-5">
          <li>
            Terms of service:{" "}
            <a href="https://xdistromusic.com/terms">
              https://xdistromusic.com/terms
            </a>
          </li>
        </ol>
      </div>
    ),
  },

  {
    id: 10,
    header: <h1>How do I upload my music to XDistro Music?</h1>,
    body: (
      <div className="leading-6">
        It’s super easy to upload your music on XDistro Music.
        <p className="my-3">
          Just head over to "new upload" on your dashboard, our content builder
          walks you through everything you need to do to get your music up
          successfully.
        </p>
        <p className="my-3">[Upload form guide]</p>
        <p className="my-3">
          {" "}
          <span className="font-bold">Services:</span> This section is where you
          can select which services you want your music to be sent to. Simply
          check the boxes next to your desired services and uncheck the boxes of
          the services you wish to leave out.
        </p>
        <p className="my-3">
          <span className="font-bold">Artist Name:</span> This is where you
          input the name of the Main artist(s) of the release. The Main artist
          or "performer" is the major artist at the album level. The main
          artist(s) will be listed on every track on the album.
        </p>
        <p className="my-3">
          <span className="font-bold">Album title:</span> Here you need to input
          a title for your album. If you are submitting more than one song, then
          you will have to input titles for all the tracks.
        </p>
        <p className="my-3">
          <span className="font-bold">Release date:</span> The release date
          section of the upload form is where you indicate when you would like
          your release to go live in stores.
        </p>
        <p className="my-3">
          <span className="font-bold">Previously released:</span> Here you
          indicate whether or not the release has been previously distributed.
          Your choice on this question will dictate whether you can choose a
          past or a future release date. If you selected Yes under “Previously
          Released?”, then this is the section where you can indicate the
          original release date for the project.
        </p>
        <p className="my-3">
          <span className="font-bold">Pre-order:</span> Here, you can choose
          whether or not you would like to opt your release into Pre-Order in
          services that support it.
        </p>
        <p className="my-3">
          <span className="font-bold">Record label:</span> Here you can input a
          custom label for your music.
        </p>
        <p className="my-3">
          <span className="font-bold">Album cover:</span> This section is where
          you upload your music cover design. High quality cover art formats
          (jpg, png) with dimensions 3000 x 3000 works best.
        </p>
        <p className="my-3">
          <span className="font-bold">Album price:</span> You can indicate a
          custom album price in this section. If your release has more than 1
          song then in this section you can set the album price for iTunes and
          Amazon, you have options ranging from $1.99 to $14.99.
        </p>
        <p className="my-3">
          <span className="font-bold">Language:</span> This must select the
          primary language used on the release. The language selected here must
          match the language used for the track titles.
        </p>
        <p className="my-3">
          <span className="font-bold">Audio file:</span> This is the most
          important, the reason we are here in the first place. This section is
          where you get to select the audio file for your track.
        </p>
        <p className="my-3">
          {" "}
          Audio files should be{" "}
          <span className="font-bold">WAV, MP3, M4A, FLAC, AIFF, </span>or{" "}
          <span className="font-bold">Windows Media (WMA).</span>
        </p>
        <p className="my-3"></p>There is no limitation to the length in minutes
        that a track can be. However, albums cannot contain songs where the
        average track length is less than 60 seconds.
        <p className="my-3">
          <span className="font-bold">Songwriter/Composer/Producer:</span> For
          every original release, you will need to list the contributor’s real
          names. Typically, songwriters are listed using real names, not stage
          names or rapper names or band names. For example, Wizkid the song
          "Come Closer (feat Drake)" on Spotify. You would expect the artist
          names listed as:
          <ol className="my-3">
            <li>Wizkid</li>
            <li>Drake</li>
          </ol>
          <p className="my-3">
            However, if you look at the credits for that song on Spotify, you'll
            notice those artists' real names are listed as songwriters. Their
            real names are, respectively:
          </p>
          <ol className="my-3">
            <li>Ayodeji Balogun</li>
            <li>Aubrey Graham</li>
          </ol>
          <p className="my-3">
            These real names will be displayed when a listener views the
            "credits" of a song.
          </p>
        </p>
        <p className="my-3">
          <span className="font-bold">Explicit lyrics:</span> If your release
          has explicit lyrics you’ll need to indicate so in this section.
        </p>
        <p className="my-3">
          <span className="font-bold">Instrumental:</span> In this section you
          can indicate whether your song contains lyrics or is just an
          instrumental.
        </p>
        <p className="my-3">
          <span className="font-bold">Track Price:</span> Here you can indicate
          a custom track price for iTunes and Amazon. You can select $0.69,
          $0.99, or $1.29, just keep in mind that tracks over 10-minutes long
          will be priced higher.
        </p>
      </div>
    ),
  },

  {
    id: 11,
    header: <h1>What are UPC and ISRC codes?</h1>,
    body: (
      <div className="leading-6">
        These are unique identifiers – an ISRC (International Standard Recording
        Code) identifies a specific recording, and a UPC (Universal Product
        Code) identifies a bundle, such as a single, EP, or album. These are
        required for every release, but don’t worry if you don’t already have
        them, as we will assign them free of charge. If you want us to assign
        them, just leave these fields blank when uploading your release.
      </div>
    ),
  },

  {
    id: 12,
    header: <h1>When will I get paid?</h1>,
    body: <div className="leading-6">We send out payments monthly.</div>,
  },

  {
    id: 13,
    header: <h1>How much of my sales does XDistro Music keep?</h1>,
    body: (
      <div className="leading-6">
        XDistro Music keeps just 20% Revenue from Artist plan users and none
        from Pro and Label Plan Users.
      </div>
    ),
  },

  {
    id: 14,
    header: (
      <h1>
        Do I need to take down my music on other distributors to switch to
        XDistro Music?
      </h1>
    ),
    body: (
      <div className="leading-6">
        Yes you do, otherwise there will be delays in your music being
        distributed through us. If you include the UPC/EAN Code when uploading
        music into XDistro Music, you’ll retain all of your streaming data for
        previously released music. It’s that easy.
      </div>
    ),
  },

  {
    id: 15,
    header: <h1>How do I enter my payment preference? </h1>,
    body: (
      <div className="leading-6">
        XDistro Music supports PayPal and Bank Transfer payout options. <br />
        To select your preferred method, navigate to your wallet page and select
        your payout option.
        <p className="my-3">Fill out all required information and submit.</p>
        <p>
          Note: We're unable to help with any tax questions/issues. Please see a
          tax professional.
        </p>
      </div>
    ),
  },

  {
    id: 16,
    header: <h1>Is There a Minimum Payout Threshold?</h1>,
    body: <div className="leading-6">The current payout threshold is $50</div>,
  },

  {
    id: 17,
    header: <h1>What is royalty split? </h1>,
    body: (
      <div className="leading-6">
        Royalty split is a tool you can use on our service to automatically pay
        other collaborators you have on your tracks or albums.
        <p className="my-3">
          With the royalty split tool, you can share earnings from any song or
          album, and automatically send those earnings to other XDistro Music
          users (collaborators, producers, contributors, managers and more). To
          get started, sign into your XDistro Music account and click "Royalty
          Splits".
        </p>
        <p className="my-3">
          Note: Each collaborator will need to have an XDistro Music account to
          collect their earnings.
        </p>
        <p className="my-3"></p>Until they accept your invitation, XDistro Music
        will hold your collaborator’s assigned split of the earnings. Once they
        accept and create an account, the held earnings will be available in
        their own account within 24 hours.
      </div>
    ),
  },

  {
    id: 18,
    header: <h1>How do I change my password?</h1>,
    body: (
      <div className="leading-6">
        Go to xdistromusic.com and click "Sign In" --- "Forgot your password?"
        <p className="my-3">
          You will then be asked to enter the email you used when signing up and
          we'll send an email with steps to reset your password.
        </p>
      </div>
    ),
  },

  {
    id: 19,
    header: <h1>How do I upgrade my account?</h1>,
    body: (
      <div className="leading-6">
        Simply login to your XDistro Music account and navigate to account
        upgrade. Choose a higher plan you wish to upgrade to and proceed. All
        your previous releases will be intact and safe, your account will
        however be upgraded and additional features added.
      </div>
    ),
  },
];

export default text;
