import Header from "../../components/Header/Header";
import DashNav from "../../components/DashNav/DashNav";

interface Prop {
  heading: string;
}

interface Item {
  icon?: React.ReactNode;
  text: string;
  to: string;
}

function SettingsHeader({ heading }: Prop) {
  const items: Item[] = [
    {
      text: "Overview",
      to: "/dashboard/settings",
    },
    {
      text: "Subscription",
      to: "/dashboard/settings/subscriptions",
    },
    {
      text: "Receipts",
      to: "/dashboard/settings/receipts",
    },
    {
      text: "Api",
      to: "/dashboard/settings/api",
    },
  ];
  return (
    <div>
      <Header children={heading} />
      <div className="my-8 flex md:bg-white rounded-[10px]">
        {items.map((item, key) => (
          <div key={key}>
            <DashNav to={item.to}>
              {item.text}
            </DashNav >
          </div>
        ))}
      </div>
    </div>
  );

}

export default SettingsHeader;
