import React, { useState } from "react";
import WorldMap from "react-world-map";

import "./TerritoryMap.css";

function Map() {
  const [selected, onSelect] = useState(null);
  return (
    <div className="map">
      <WorldMap selected={selected} onSelect={onSelect} />
    </div>
  );
}

export default Map;
