import { ChangeEvent } from "react";

interface Data {
  id?: string;
  placeholder: string;
  info?: React.ReactNode;
  infoClass?: string;
  type: string;
  min?: string;
  max?: string;
  label?: string;
  className?: string;
  error?: string;
  icon?: React.ReactNode;
  backgroundColor?: string;
  rounded?: string;
  text?: string;
  borderColor?: string;
  border?: string;
  focus?: string;
  value?: any;
  labelColor?: string;
  labelClassName?: string;
  inputClassName?: string;
  name?: string;
  multiple?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
}

const preventMinus = (e: { code: string; preventDefault: () => void }) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

const Input: React.FC<Data> = ({
  id,
  type,
  placeholder,
  label,
  className,
  error,
  icon,
  backgroundColor,
  rounded,
  min,
  borderColor,
  focus,
  border,
  value,
  labelColor,
  labelClassName,
  inputClassName,
  onChange,
  multiple,
  name,
  info,
  infoClass,
  accept,
}) => {
  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={id}
          className={`flex font-normal text-xs ${
            labelColor || "text-xd_grey"
          } my-3 ${labelClassName}`}>
          <div className="flex gap-2 items-center">
            <div>
              <div className={`${info ? "text-start" : ""}`}>{label}</div>
              <div className={`text-start opacity-50 ${infoClass}`}>{info}</div>
            </div>
            <div>{icon}</div>
          </div>
        </label>
      ) : (
        ""
      )}
      <input
        id={id}
        accept={accept}
        className={`p-4 ${rounded || "rounded-md"} ${
          focus ||
          "focus:outline-none focus:border-xd_yellow focus:ring-xd_yellow"
        } ${borderColor || "border-xd_grey"} ${
          border || "border-[1px]"
        } w-full font-normal text-sm text-black placeholder-zinc-300 focus:ring-transparent  ${
          backgroundColor || ""
        } ${inputClassName}`}
        type={type}
        placeholder={placeholder}
        min={min}
        defaultValue={value}
        onChange={onChange}
        name={name}
        multiple={multiple}
      />

      {error ? (
        <p className="text-red text-xs font-light my-2">{error}</p>
      ) : null}
    </div>
  );
};

export default Input;

