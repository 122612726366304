import Features from "../components/Features/Index";
import Faq from "../components/Faq/Faq";
import Navbar from "../components/NavBar/Nav";
import Plans from "../components/Prices/Index";
import Stores from "../components/Stores/Stores";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import { Link } from "react-router-dom";
// import AllMusic from "../components/AllMusic/Card";
import Hero from "../components/HeroSection/hero";
import { useState, useEffect } from "react";

function Home() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  // const isMobile =

  return (
    <section className="relative min-h-screen">
      <div className="relative">
        <Navbar />
      </div>
      {/*<Hero
        header="Take Control of Your Music."
        subheader={
          <div className="leading-[27px] text-center md:text-start">
            <div>
              Sell your music and keep 100% of your earnings.
              <br />
              <em>Get paid Monthly!</em>
            </div>
          </div>
        }
        background="bg-[url(https://res.cloudinary.com/dbeouvd75/image/upload/v1724969818/xdistro-video1_xejygf-ezgif.com-video-to-webp-converter_t0kajr.webp)] bg-cover bg-center"
        action={
          <div className="flex justify-center md:justify-start">
            <Button color="bg-white">
              <Link to="/signup">
                Get Started
              </Link>
            </Button>
          </div>
        }
      />*/}
      <div className="relative overflow-hidden md:bg-none bg-HeroImg bg-center bg-cover">
        <div className="relative min-h-screen md:mt-0 mt-20  bg-black bg-opacity-50">

          {width <= 768 ? null :
            <video src="https://res.cloudinary.com/dbeouvd75/video/upload/q_auto:low/v1724942535/xdistro-video1_xejygf.webm"
              className="hidden md:block min-h-screen object-cover max-h-full min-w-full top-0 left-0 -z-xd_z1" muted autoPlay controls={false} loop={true}></video>}

          <div className="bg-opacity-70 absolute top-0 w-full h-full">
            <div className="px-2 lg:px-32 py-5 absolute top-[50%] -translate-y-1/2 inline-block align-middle w-full">
              <div className="px-6">
                <div className="lg:basis-1/3 md:basis-1/2 lg:w-full text-white">
                  <h1 className="font-sans font-bold lg:text-5xl text-6xl text-center md:text-start">
                    Take Control of Your Music.
                  </h1>
                  <div className="my-10">
                    <div className="leading-[27px] text-center md:text-start">
                      <div>
                        Sell your music and keep 100% of your earnings.
                        <br />
                        <em>Get paid Monthly!</em>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center md:justify-start">
                    <Button color="bg-white">
                      <Link to="/signup">
                        Get Started
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="">
        <div className="my-10 space-y-16 md:space-y-24 md:px-32 px-4">
          <Stores />
          <Plans />
          <Features />
          <Faq />
        </div>
        <Footer />
      </section>
    </section>
  );
}

export default Home;
