import React from 'react'
import Footer from '../components/Footer/Footer'
import Hero from '../components/HeroSection/hero'
import Navbar from '../components/NavBar/Nav'
import Music from '../data/MusicListData'
import MusicContent from '../components/Music/MusicContent'

function MusicDistribution() {
  return (
    <div>
      <Navbar />
      <Hero
        action=''
        background='md:bg-Music bg-MobileMusic bg-right'
        header='Digital Distribution Stores'
        subheader='With XDistro, you get upload access to all known stores like Spotify, Apple Music, iTunes, Amazon Music, and 150 more digital stores.' />
      <div className="mt-12 px-5 ">
        <div className="lg:px-36 px-2 divide-y divide-black">
          {Music.map((text, i) => {
            return (
              <div className="mt-7" key={i}>
                <MusicContent key={text.id} Icon={text.icon} children={text.body} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default MusicDistribution
