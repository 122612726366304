// import Button from "../../components/Button/Button";
import TermsAndPolicies from "../../components/TermsAndPolicies/TermsAndPolicies";
import SettingsHeader from "./SettingsHeader";
import { useDashboardContext } from "../../components/Dashboard/DashboardContext";
import moment from "moment";
import User from "../../assets/user.png";
import * as Yup from "yup";
import { useFormik, FieldInputProps } from "formik";
import { useState } from "react";
import sendRequest from "../../components/axios";

interface ProfileDetailProps {
  title: string;
  value?: string;
  inputProps: FieldInputProps<string>;
  shouldEdit: boolean
}

function ProfileDetail({ title, value, inputProps, shouldEdit }: ProfileDetailProps) {
  return (
    <div className="border-b">
      <div className="grid grid-cols-2 px-4 py-3">
        <div className="capitalize text-xd_grey">
          {title}
        </div>
        <div className="text-end text-xd_grey">
          <p className="w-full text-right outline-green-50">{value}</p>
          {/*<input
            {...inputProps}
            disabled={!shouldEdit}
            className="w-full p-2 text-right border-b  outline-green-50"
            value={value || ""} />*/}
        </div>
      </div>
    </div>
  )
}

function Settings() {
  const { user } = useDashboardContext();
  const [shouldEdit, setShouldEdit] = useState(false);

  const { handleSubmit, values, errors, getFieldProps } = useFormik({
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      username: user?.username || "",
      phone: user?.phone || "",
      record_label: user?.record_label || "",
      country: user?.country || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      username: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      record_label: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      if (user.first_name == values.first_name &&
        user.last_name == values.last_name &&
        user.email == values.email &&
        user.username == values.username &&
        user.phone == values.phone &&
        user.record_label == values.record_label &&
        user.country == values.country) {
        setShouldEdit(false);
        return;
      }
      // call api to update user
      sendRequest("PUT", "/api/v1/me", values).then((res) => {
        console.log(res);
        if (res.status === 200) {
          setShouldEdit(false);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
  });
  return (
    <div>
      <div>
        <SettingsHeader heading="Overview" />
        <div className="flex justify-center">
          <div>
            <div className="flex flex-col md:flex-row items-center gap-7">
              <div className="w-40">
                <img
                  src={user?.image ? user?.image : User}
                  alt="user"
                  className="rounded-full"
                />
              </div>
              <div>
                <div className="font-semibold text-[40px]">{user?.username}</div>
                <div className="font-medium text-base text-xd_grey">
                  (since {moment.unix(user.created_at || 0).format("LL")})
                </div>
              </div>
              <div className="flex items-center gap-7">
                <div>
                  <div className="font-medium text-[20.18px] text-xd_lightblack">
                    Artist
                  </div>
                  <div className="flex justify-center font-medium text-[46.57px]">
                    {user?.num_artists || 0}
                  </div>
                </div>
                <div>
                  <div className="font-medium text-[20.18px] text-xd_lightblack">
                    Releases
                  </div>
                  <div className="flex justify-center font-medium text-[46.57px]">
                    {user?.num_releases || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="bg-white font-medium md:text-base text-xs border text-start rounded-[10px]">
                <form>
                  <ProfileDetail shouldEdit={shouldEdit} title="First Name" inputProps={getFieldProps("first_name")} value={user?.first_name} />

                  <ProfileDetail shouldEdit={shouldEdit} title="Last Name" inputProps={getFieldProps("last_name")} value={user?.last_name} />

                  <ProfileDetail shouldEdit={shouldEdit} title="Email" inputProps={getFieldProps("email")} value={user?.email} />

                  <ProfileDetail shouldEdit={shouldEdit} title="Username" inputProps={getFieldProps("username")} value={user?.username} />

                  <ProfileDetail shouldEdit={shouldEdit} title="Phone Number" inputProps={getFieldProps("phone")} value={user?.phone} />

                  <ProfileDetail shouldEdit={shouldEdit} title="Record Label" inputProps={getFieldProps("record_label")} value={user?.record_label} />

                  <ProfileDetail shouldEdit={shouldEdit} title="Country" inputProps={getFieldProps("country")} value={user?.country} />
                </form>
              </div>
            </div>
            <div className="flex items-center">
              <input
                id="link-checkbox"
                type="checkbox"
                value=""
                className="form-check-input appearance-none bg-CheckBoxIcon p-2 checked:bg-CheckBoxActive focus:outline-none transition duration-200 bg-no-repeat bg-center bg-containcursor-pointer" />
              <label htmlFor="link-checkbox" className="text-base ml-2">Keep me posted with the latest independent music & indie artists tips.</label>
            </div>
            {/*
            <div className="text-center my-8">
              <Button
                onClick={() => {
                  // if (shouldEdit) {
                  //   handleSubmit();
                  //   return;
                  // }
                  // setShouldEdit(!shouldEdit);
                }}
                children={shouldEdit ? "Save Changes":"Edit Profile"}
                color=""
                className="w-full font-medium text-sm text-[#BDBDBD]"
              />
            </div>*/}
          </div>
        </div>
      </div>
      <div className="my-10">
        <TermsAndPolicies />
      </div>
    </div>
  );
}

export default Settings;
