import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Track } from "../../interfaces/release";
import Action from "../Action/Action";

function RoyaltySplitActions({ track }: { track: Track }) {
  const navigate = useNavigate();
  return (
    <Action
      body={[
        {
          text: "Edit Split",
          action: () => {
            if (track.release?.status === "ready") {
              navigate(`/dashboard/royalty-splits/${track.id}`);
            } else {
              toast.info(track.release?.status === "draft" ? "Track is not ready for split" : "Track is already published");
            }
          },
        },
      ]}
    />
  );
}

export default RoyaltySplitActions;
