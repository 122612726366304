import { useState } from "react";
import { Link } from "react-router-dom";
import sendRequest from "../components/axios";
import Button from "../components/Button/Button";
import Input from "../components/Input/Index";
import AuthLayout from "./AuthLayout";
import { PuffLoader } from "react-spinners";
import ReactGA from "react-ga4";

function Login() {
  // set page title
  document.title = "Login - XDistro Music";
  //const [user, setUser] = useState<IUser>();
  const [apiMessage, setApiMessage] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setApiMessage("");
    setApiError("");
    event.preventDefault();
    // TODO: make sure user agrees to terms and conditions
    // TODO: add env variables to vercel
    const t: any = event.target;
    const loginRequest = {
      //...user,
      username: t.username.value,
      password: t.password.value,
    };
    //setUser(loginRequest);
    sendRequest("POST", "/api/v1/user/login", loginRequest)
      .then((res) => {
        setApiMessage(res.data.message);
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data.user));
        let redirect = localStorage.getItem("redirect");
        if (redirect) {
          localStorage.removeItem("redirect");
        } else {
          redirect = "/dashboard/my-music";
        }
        if (process.env.REACT_APP_GA4_ID) {
          ReactGA.event({
            category: "User",
            action: "login",
            label: "successful", // optional
            // value: , // optional, must be a number
            // nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
        window.location.href = redirect;
      })
      .catch((err) => {
        console.log({ err });
        if (err.response) {
          setApiError(err.response.data?.errors);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <AuthLayout>
      <h1 className="text-3xl md:text-big">LOGIN</h1>
      <p className="text-xd_gray font-normal text-sm my-2">
        Don't have an account yet?{" "}
        <Link to="/signup" className="text-blue-600">
          signup
        </Link>
      </p>
      <div className="mt-7">
        {apiMessage ? (
          <p className="text-green-700 font-normal text-sm">{apiMessage}</p>
        ) : (
          ""
        )}
        {apiError ? (
          <p className="text-xd_red font-normal text-sm">{apiError}</p>
        ) : (
          ""
        )}
        <form onSubmit={submit}>
          <div className="">
            <Input
              className=""
              id="username"
              placeholder="Email/Username"
              type="text"
              label="Email/Username"
            // error="password must be a minimum of 8 digits"
            />
          </div>
          <div className="mt-7">
            <Input
              className=""
              id="password"
              placeholder="Password"
              type="password"
              label="Password"
            // error="password must be a minimum of 8 digits"
            />
          </div>
          <p className="text-right">
            <Link
              to="/forgot-password"
              className="text-sm italic text-blue-600"
            >
              forgot password?
            </Link>
          </p>

          <div className="mt-5">
            <Button disabled={loading} type="submit" className="w-full">
              {loading ? <PuffLoader className="h-4 w-4" size={30} color="white" /> : "Next "}
            </Button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default Login;
