import EarningStats from "../../components/EarningStats/EarningStats";
import Header from "../../components/Header/Header";
import Payment from "../../components/PaymentMethod/Payment";
import TermsAndPolicies from "../../components/TermsAndPolicies/TermsAndPolicies";
import Transactions from "../../components/Transactions/Transaction";

function Wallet() {
  return (
    <div>
      <Header children={"Wallet"} />
      <section className="space-y-5 mt-7">
        <div className="md:flex gap-5 h-full">
          <div className="md:basis-1/2 w-full ">
            <Payment />
          </div>
          <div className="md:basis-1/2 w-full md:mt-0 mt-10">
            <EarningStats />
          </div>
        </div>
        <div className="">
          <Transactions />
        </div>
        <div className="">
          <TermsAndPolicies />
        </div>
      </section>
    </div>
  );
}
export default Wallet;
