import { useEffect, useState } from "react";
import ISubscriptionPlan from "../../interfaces/subplan";
import sendRequest from "../axios";
import Price from "./Price";

const Index = () => {
  const [plans, setPlans] = useState<ISubscriptionPlan[]>([]);

  const getPlans = () => {
    sendRequest("GET", "/api/v1/subscriptionplans", null).then(res => {
      // console.log({plans: res.data});
      setPlans(res.data.data);
    });
  }

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="my-2 container mx-auto">
      <h1 className="font-bold text-center text-4xl">Choose Your Plan</h1>
      <div className="px-10 text-center font-medium text-lg text-xd_gray">
        Take control of your music, select from our diverse plans.
      </div>
      <div className="my-10 md:grid grid-cols-2 gap-10 w-full justify-center space-y-4 md:space-y-0">
        {plans.map((p, index) => {
          return (
            <Price index={index} key={index} {...p} />
          );
        })}
      </div>
    </div>
  );
};

export default Index;
