import UploadBanner from "../../components/UploadBanner/UploadBanner";
import Header from "../../components/Header/Header";
import Releases from "../../components/ReleaseTable/Releases";
import TermsAndPolicies from "../../components/TermsAndPolicies/TermsAndPolicies";
import { useDashboardContext } from "../../components/Dashboard/DashboardContext";
import Alert from "../../components/alert";
import { useState } from "react";
import { PaymentModal } from "../../components/Prices/Price";

function MyMusic() {
  const { currentPlan, plans } = useDashboardContext();
  return (
    <div className="space-y-8">
      <div>
        <Header children="My Music" />
      </div>
      <div className="space-y-4">
        {plans.length ?
          currentPlan ? null : <Alert body={
            <div>
              Select a plan so you can start releasing your music:
              {plans?.map((p, index) => <SubscriptionButton key={index} plan={p} />)}
            </div>}
          /> : null}
        <UploadBanner
          header="Upload New Music"
          subheader="Create a new release in just a few minutes"
          buttonText="Start Upload"
        />
      </div>
      <Releases />
      <div className="my-10">
        <TermsAndPolicies />
      </div>
    </div>
  );
}

function SubscriptionButton({ plan }: { plan: any }) {
  const [openModal, setOpenModal] = useState(false);
  return <button className="ml-2 underline" onClick={() => { setOpenModal(true) }}>
    <PaymentModal id={plan.id} openModal={openModal} setOpenModal={setOpenModal} />
    {plan.name}
  </button>
}

export default MyMusic;
