import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  children?: React.ReactNode;
  to: string;
}

function DashNav({ children, to}: Props) {
  const path = window.location.pathname;
  return (
    <div className='md:bg-white w-full rounded-[10px] items-center'>
      <div className='p-3 flex md:gap-5 gap-3'>
        <Link to={to} className={`${path=== to ? "border border-xd_black text-black": "border text-xd_grey"} py-1 px-2 bg-[#F2F2F2] rounded-[10px]`}>
            <div className='flex'>{children}</div>
        </Link>
      </div>
    </div>
  )
}

export default DashNav;