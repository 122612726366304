import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import FileUpload, { readFileAsDataURL } from "../FileUpload/FileUpload";
import { CancelIcon, FileIcon } from "../Icons/Icons";
import Input from "../Input/Index";
import Slider from "../Slider/Slider";
import XDSwitch from "../Switch/Index";
import Tooltip from "../Tooltip/Tooltip";
import CheckBox from "../CheckBox/CheckBox";
import {
  Track,
  TrackContributor,
  TrackDetails,
} from "../../interfaces/release";
import TrackPreview from "./TrackPreview";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import { roles } from "../../data/contributors";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getFileName } from "../../utils/file";
import languages from "../../data/languages";
import { IArtist } from "../../interfaces/artist";

const ContributorSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  role: Yup.string().required("Role is required."),
});

interface Props {
  details: TrackDetails;
  onChange: (track: TrackDetails) => void;
  toggleEditDetails: () => void;
  releaseArtist?: IArtist;
}

function TrackListDetails({ details, releaseArtist, onChange, toggleEditDetails }: Props) {
  const { artists } = useDashboardContext();
  const [trackDetails, setTrackDetails] = useState<TrackDetails>(details);
  const [contributors, setContributors] = useState<TrackContributor[]>(
    details.contributors || []
  );
  const [hasVocals, setHasVocals] = useState<boolean>(true);
  const [lyricFileName, setLyricFileName] = useState<string>(
    getFileName(details.lyrics || "")
  );

  // some stuff to fix
  // - fix preview time, it should show properly, right now it's just sending the number of seconds
  // - fix release artist: it's sending xdistro the backend instead of the artist name
  // - copyright doc is empty - done
  // - store contributors of the tracks
  function setFullTrackTitle() {
    const feat = contributors
      .filter((t) => t.role === "Featured Artist")
      .map((t) => `${t.name}`)
      .join(", ");
    setTrackDetails((prev) => {
      return {
        ...prev,
        track_full_title: `${prev.title} ${feat ? "feat (" + feat + ")" : ""}`,
      };
    });
    // trackDetails.track_full_title = `${trackDetails.title} ${feat ? "feat (" + feat + ")" : "" }`;
    // onChange(trackDetails);
  }

  useEffect(() => {
    setContributors((prev) => {
      // check if the artist is already in the list
      const artistExists = prev.some((contributor) => {
        return contributor.name === releaseArtist?.name;
      });
      if (artistExists) return prev;
      if (!releaseArtist) return prev;
      return [
        ...prev,
        {
          // TODO: use the artist that was passed to this component
          name: releaseArtist.name,
          role: "Primary Artist",
          full_name: releaseArtist.name,
          artist_id: releaseArtist.id,
        },
      ];
    });
    setFullTrackTitle();
  }, [artists]);

  useEffect(() => {
    onChange(trackDetails);
  }, [trackDetails]);

  useEffect(() => {
    trackDetails.contributors = contributors;
    setTrackDetails(trackDetails);
    setFullTrackTitle();
  }, [contributors]);

  return (
    <div className="px-2 lg:px-10 my-10">
      <div className="flex">
        <div className="flex gap-10 items-center basis-[100%]">
          <div className="text-xd_grey">Editing Track Details</div>
        </div>
        {/*<div>
          <CancelIcon />
        </div>*/}
      </div>

      <div>
        <div className="lg:flex lg:gap-40 items-end  gap-10">
          <div className="basis-1/2">
            <Input
              className=""
              value={trackDetails.title}
              id="track_title"
              placeholder="Track Title"
              type="text"
              label="Track Title"
              backgroundColor="bg-transparent"
              onChange={(e) => {
                trackDetails.title = e.target.value;
                setTrackDetails(trackDetails);
                setFullTrackTitle();
                // onChange(trackDetails);
              }}
            />
          </div>
          <div className="basis-1/2">
            <p className="font-normal text-xs text-xd_grey my-3">
              Full Track Name
            </p>
            <div className="p-4 rounded-md border-xd_grey border-[1px] w-full font-normal text-sm text-black bg-gray-50">
              {trackDetails.track_full_title}
            </div>
            {/*
            <Input
              className=""
              value={trackDetails.track_full_title}
              id="full_track_name"
              placeholder="full name of track"
              type="text"
              label="Full Track Name"
              backgroundColor="bg-transparent"
            // onChange={(e) => {
            // details.track_name = e.target.value;
            // onChange(details);
            // }}
            />
          */}
          </div>
        </div>

        {/* TODO: add featured artists */}

        <div className="my-5 space-y-2">
          <p className="font-normal text-xs text-xd_grey my-3">Contributors:</p>
          {contributors.map((artist, index) => {
            return (
              <div
                key={index}
                className="w-full p-4 border-[1px] rounded-md font-normal text-sm text-black border-black bg-white">
                <div className="flex justify-between">
                  <div>
                    <span className="mr-2">{artist.name}</span>
                    <span className="text-gray-400">({artist.role})</span>
                  </div>
                  <div>
                    {artist.role.toLowerCase() !== 'primary artist' ? <span onClick={() => {
                      setContributors(prev => {
                        prev.splice(index, 1)
                        return [...prev];
                      })
                    }} className="cursor-pointer text-red">
                      x
                    </span> : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <ContributorForm
          addNewContributor={(contributor) => {
            // TODO: add functionality to remove contributor
            // console.log({contributor})
            setContributors((prev) => {
              if (
                prev.some(
                  (t) =>
                    t.name === contributor.name && t.role === contributor.role
                )
              ) {
                // TODO: add error message here
                // console.log("contributor already exists");
                return prev;
              }
              return [...prev, contributor];
            });
          }}
        />

        <div className="space-y-6">
          <div className="md:flex gap-8 space-y-6 items-baseline">
            <div className="basis-1/2">
              <TrackPreview
                defaultPreview={trackDetails.preview}
                setPreview={(preview) => {
                  trackDetails.preview = preview;
                  onChange(trackDetails);
                }}
                audioFile={details.track}
              />
            </div>

            <div className=" basis-1/2">
              <Input
                className=""
                id="isrc_code"
                placeholder=""
                type="text"
                label="ISRC code"
                value={trackDetails.isrc_code}
                onChange={(e) => {
                  trackDetails.isrc_code = e.target.value;
                  onChange(trackDetails);
                }}
                icon={
                  <Tooltip
                    value={`The International Standard Recording Code (ISRC) is an international standard code for uniquely
                    identifying sound recordings and music video recordings. If you do not have one, you can leave 
                    this box empty and we'll add one to your track.`}
                  />
                }
                backgroundColor="bg-transparent"
              />
            </div>
          </div>

          <div className="md:flex gap-8 space-y-6 items-baseline">
            <div className="basis-1/2">
              <div className="my-5 flex items-center gap-5">
                Does this track contain vocals
                <XDSwitch
                  checked={hasVocals}
                  onChange={(hasVocals) => {
                    // console.log(hasVocals);
                    setHasVocals(hasVocals);
                  }}
                />
              </div>

              {hasVocals ? (
                <div className="lg:flex gap-40 my-5">
                  <div className="basis-1/2">
                    <select
                      id="language"
                      value={trackDetails.vocals_language}
                      onChange={(e) => {
                        trackDetails.vocals_language = e.target.value;
                        onChange(trackDetails);
                      }}
                      className="p-4 items-center rounded-md focus:outline-xd_yellow border-xd_grey border-[1px] w-full font-normal text-xs">
                      <option value="" disabled>Select Language</option>
                      {languages.map((language, index) => (
                        <option key={index} value={language}>
                          {language}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="basis-1/2"></div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="basis-1/2">
              <div className="text-xd_grey">Have Lyrics you want to add?</div>
              <div className="text-red-500">
                <p>
                  Please note that lyrics are mandatory for your release to go
                  live on Apple Music.
                </p>
                <p>
                  If you do not have lyrics, your release will not be accepted on
                  apple music.
                </p>
              </div>
              <div className="my-5">
                <FileUpload
                  height="h-20"
                  icon={<FileIcon />}
                  children={
                    lyricFileName
                      ? lyricFileName
                      : "Drag & Drop .docx/.txt or Click to upload lyrics"
                  }
                  row="flex-row-reverse w-full"
                  id="lyrics"
                  name="lyrics"
                  accept=".txt"
                  onChange={(e) => {
                    if (!e.target.files) return;
                    setLyricFileName(e.target.files[0].name);
                    // convert the file to base64
                    readFileAsDataURL(e.target.files[0]).then((data: string) => {
                      setTrackDetails((prev) => ({ ...prev, lyrics: data }));
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="my-10">
            Explicit Content
            <div className="my-5 lg:flex gap-20">
              <div className="flex items-center gap-5">
                <CheckBox
                  id="is_explicit"
                  name="explicit"
                  value="is_explicit"
                  defaultChecked={trackDetails.is_explicit}
                  onChange={(e) => {
                    if (e.target.checked) {
                      trackDetails.is_explicit = true;
                      onChange(trackDetails);
                    }
                  }}
                />
                <label htmlFor="is_explicit">
                  <h1>Explicit Content</h1>
                  <p>This track includes profanity or offensive language</p>
                </label>
              </div>

              <div className="flex items-center gap-5">
                <CheckBox
                  id="not_explicit"
                  name="explicit"
                  value="not_explicit"
                  defaultChecked={!trackDetails.is_explicit}
                  onChange={(e) => {
                    if (e.target.checked) {
                      trackDetails.is_explicit = false;
                      onChange(trackDetails);
                    }
                  }}
                />
                <label htmlFor="not_explicit">
                  <h1>Not Explicit Content</h1>
                  <p>
                    This track does NOT include profanity or offensive language
                  </p>
                </label>
              </div>
            </div>
            <div className="flex lg:justify-end justify-center">
              <Button
                onClick={toggleEditDetails}
                textColor="text-black"
                className="lg:px-[30px] px-[40%]">
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContributorForm({
  addNewContributor,
}: {
  addNewContributor?: (contributor: TrackContributor) => void;
}) {
  let { handleSubmit, getFieldProps, handleChange, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: "",
        role: "",
        // full_name: "",
      },
      validationSchema: ContributorSchema,
      onSubmit: (values, { resetForm }) => {
        addNewContributor &&
          addNewContributor({ ...values } as TrackContributor);
        resetForm();
      },
    });

  return (
    <div className="my-5 bg-white p-5">
      <p className="text-xd_red">{errors.name ? `${errors.name}` : ""}</p>
      <p className="text-xd_red">{errors.role ? `${errors.role}` : ""}</p>
      <form onSubmit={handleSubmit} id={"contributor-form"}>
        <Input
          className=""
          id="name"
          placeholder="Full name if the contributor is a writer or composer"
          type="text"
          label="Name"
          backgroundColor="bg-transparent"
          {...getFieldProps("name")}
          icon={
            <Tooltip
              value={`Writers' true first and last names must be used. (eg: “Edward
                Sheeran”, not “Ed Sheeran”)`}
            />
          }
        />

        <div className="flex flex-wrap lg:gap-20 my-3 gap-4">
          {roles.map((role, index) => {
            return (
              <div key={index} className="flex lg:gap-3 gap-1">
                <CheckBox
                  id={role.id}
                  name="role"
                  value={role.id}
                  onChange={() => {
                    handleChange({
                      target: {
                        name: "role",
                        value: role.name,
                      },
                    });
                  }}
                />
                <label htmlFor={role.id}>{role.name}</label>
              </div>
            );
          })}
        </div>

        <div className="flex lg:justify-end justify-center">
          <Button textColor="text-black" type="submit">
            Add New Contributor
          </Button>
        </div>
      </form>
    </div>
  );
}

export default TrackListDetails;
