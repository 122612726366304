import React from "react";
import {
  SpotifyIcon,
  TidalIcon,
  AppleMusicIcon,
  YouTubeMusicIcon,
  SpotifyTextIcon,
  BoomplayMusicIcon,
  AppleMusicTextIcon,
  TidalTexticon,
  TiktokIcon,
  YoutubeIcon,
} from "../Icons/MusicDistributionIconList";
import AudioMackIcon from "../Icons/AudioMack";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import { DeezerIcon, InstagramIcon } from "../Icons/Icons";

const Stores = () => {
  const storeIcons = [
    {
      icons: <SpotifyTextIcon fullWidth />,
    },
    {
      icons: <TiktokIcon fullWidth />,
    },
    {
      icons: <AppleMusicTextIcon fullWidth />,
    },
    {
      icons: <TidalTexticon fullWidth />,
    },
    {
      icons: <DeezerIcon />,
    },
    {
      icons: <InstagramIcon />,
    },
    {
      icons: <YoutubeIcon fullWidth />,
    },
    {
      icons: <BoomplayMusicIcon fullWidth />,
    },
  ];
  return (
    <div className="flex justify-center items-center text-center">
      <div className="space-y-10">
        <div className="space-y-6">
          <div className="font-bold text-4xl">
            Your Music, Everywhere!
          </div>
          <div className="mx-auto text-center text-xd_gray font-medium text-lg max-w-[810px] ">
            Be everywhere, get your music available on 150+ streaming and
            download stores around the world.
          </div>
        </div>
        <div className="flex flex-wrap justify-center text-center gap-6 md:gap-8 lg:max-w-[80%] md:mx-auto">
          {storeIcons.map((s, index) => (
            <div className="md:w-40 w-24" key={index}>{s.icons}</div>
          ))}
        </div>
        <div className="">
          <Link to="/partners">
            <Button color="bg-xd_lightYellow">View Stores</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Stores;
