import { createContext, useContext, useEffect, useState } from "react";
import IRelease, { Track } from "../../interfaces/release";
import sendRequest from "../axios";
import { IArtist } from "../../interfaces/artist";
import IUser from "../../interfaces/user";
import ISubscriptionPlan from "../../interfaces/subplan";
import IPlaylistRequest from "../../interfaces/playlist_request";

interface IContextData {
  releases: IRelease[];
  getReleases: () => void;
  artists: IArtist[];
  user: IUser;
  tracks: Track[];
  plans: ISubscriptionPlan[];
  currentPlan?: ISubscriptionPlan;
  getArtists: () => void;
  artistMap: { [index: string]: boolean };
  playlistRequests: IPlaylistRequest[];
  fetchPlaylistRequests: () => void;
}

export const DashboardContext = createContext({} as IContextData);

export const useDashboardContext = () => useContext(DashboardContext);

interface Props {
  children: React.ReactNode;
}

export default function DashboardProvider({ children }: Props) {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [playlistRequests, setPlaylistRequests] = useState<IPlaylistRequest[]>(
    []
  );
  const [releases, setReleases] = useState<IRelease[]>([]);
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [plans, setPlans] = useState<ISubscriptionPlan[]>([]);
  const [currentPlan, setCurrentPlan] = useState<ISubscriptionPlan>();
  const [artistMap, setArtistMap] = useState<{ [index: string]: boolean }>({});

  useEffect(() => {
    if (user && user.subscription && plans && plans.length) {
      setCurrentPlan(
        plans.find((p) => p.id === user.subscription.subscription_plan_id)
      );
    }
  }, [user, plans]);

  const getPlans = () => {
    sendRequest("GET", "/api/v1/subscriptionplans", null).then((res) => {
      setPlans(res.data.data);
    });
  };

  function getTracks() {
    sendRequest("GET", "/api/v1/tracks", null)
      .then((res) => {
        // console.log(res.data.data);
        setTracks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getReleases() {
    sendRequest("GET", "/api/v1/release", null)
      .then((res) => {
        setReleases(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getArtists() {
    sendRequest("get", "/api/v1/artists", {})
      .then((res) => {
        setArtists(res.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  function getLoggedInUser() {
    sendRequest("GET", "/api/v1/me", {}).then((res) => {
      //console.log({user:res.data.data});
      setUser(res.data.data);
    });
  }

  function fetchPlaylistRequests() {
    sendRequest("GET", `/api/v1/playlist-support`, null).then((response) => {
      setPlaylistRequests(response.data.data);
    });
  }

  useEffect(() => {
    setArtistMap(
      artists.reduce((acc, artist) => {
        acc[artist.id] = true;
        return acc;
      }, {} as { [index: string]: boolean })
    );
  }, [artists]);

  useEffect(() => {
    getLoggedInUser();
    getTracks();
    getReleases();
    getArtists();
    getPlans();
    fetchPlaylistRequests();
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        currentPlan,
        plans,
        tracks,
        user,
        releases,
        artists,
        getArtists,
        artistMap,
        playlistRequests,
        fetchPlaylistRequests,
        getReleases,
      }}>
      {user ? children : ""}
    </DashboardContext.Provider>
  );
}
