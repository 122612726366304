import React from "react";
import { Link } from "react-router-dom";
import { RightArrowIcon } from "../Icons/MusicDistributionIconList";
import FaqSubList from "./FaqSubList";

const Faq = () => {
  return (
    <div className="my-20 container mx-auto">
      <h1 className="font-bold text-4xl text-center">
        Frequently Asked Questions
      </h1>

      <div className="my-14">
        <FaqSubList />
      </div>

      <div className="flex">
        <div className="basis-1/2 font-semibold text-medium">
          <Link to="/faq">More FAQ’s</Link>
        </div>
        <div className="basis-1/2 text-end">
          <Link to="/faq" className="inline-block">
            <RightArrowIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faq;

