import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { TrackPreview as ITrackPreview, Track } from "../../interfaces/release";
import { SmallPlayIcon } from "../Icons/Icons";
import Input from "../Input/Index";
import Slider from "../Slider/Slider";
import XDSwitch from "../Switch/Index";
import Tooltip from "../Tooltip/Tooltip";
import "./Slider.css";

interface Props {
  audioFile: string;
  setPreview: (preview: ITrackPreview) => void;
  defaultPreview: ITrackPreview;
}

// TODO: make sure start time is less than end time
const TrackPreview: React.FC<Props> = ({ audioFile, setPreview, defaultPreview }) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audioLength, setAudioLength] = useState<number>(0);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer>();
  const [intervalID, setIntervalID] = useState<any>(undefined);
  const [hasPreview, setHasPreview] = useState<boolean>(true);
  const [startTime, setStartTime] = useState(defaultPreview.start_time || 0);
  const [endTime, setEndTime] = useState(defaultPreview.end_time || 0);
  const [previewDuration, setPreviewDuration] = useState<number>(endTime - startTime);
  const [context, setContext] = useState<AudioContext>();
  const [audioBufferSource, setAudioBufferSource] = useState<AudioBufferSourceNode>();

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(convertTimeToSeconds(event.target.value));
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(convertTimeToSeconds(event.target.value));
  };

  useEffect(() => {
    if (startTime > endTime) setEndTime(startTime);
    setPreviewDuration(endTime - startTime);
    setPreview({ start_time: startTime, end_time: endTime });
  }, [startTime, endTime]);

  function startAudio() {
    if (audioBufferSource && context) {
      console.log("audioBufferSource is defined");
      // this should maybe be currenttime minus start time or endtime - current time
      audioBufferSource.start(
        context.currentTime,
        startTime,
        endTime - startTime
      ); //, startTime, endTime - startTime
    } else {
      console.log("audioBufferSource is undefined");
    }
  }

  // async function setupAudio() {
  //   const ac = new AudioContext();
  //   ac.onstatechange = (e) => {
  //     setIsPlaying(ac.state === 'running');
  //     // console.log(ac.state);
  //   }

  //   const ab = await audioFile.arrayBuffer()
  //   if (ac.state === 'running') {
  //     await ac.suspend();
  //   }
  //   const audioBuffer = await ac.decodeAudioData(ab);
  //   const absn = ac.createBufferSource();
  //   absn.buffer = audioBuffer;
  //   absn.connect(ac.destination);
  //   absn.onended = () => {
  //     console.log("ended");
  //     setIsPlaying(false);
  //     clearInterval(intervalID);
  //   };
  //   absn.start(ac.currentTime, startTime, endTime - startTime);

  //   setContext(ac);
  //   setAudioBuffer(audioBuffer);
  //   setAudioBufferSource(absn);

  // setIntervalID(
  //   setInterval(() => {
  //     console.log(ac.currentTime);
  //     const current = (ac.currentTime * 100) / audioBuffer.duration;
  //       // ((ac.currentTime + startTime) * 100) / audioBuffer.duration;
  //     setSliderValue(current);

  //     if (ac.currentTime + startTime >= endTime) {
  //       setIsPlaying(false);
  //       clearInterval(intervalID);
  //     }
  //   }, 1000));
  // }

  // useEffect(() => {
  //   const duration = endTime - startTime;
  //   setPreview({ start_time: startTime, end_time: endTime });
  //   // This is to get the duration set by the user
  //   setPreviewDuration(duration);

  //   // Set the audioLength state to the actual duration of the audio file
  //   const audio = new Audio(URL.createObjectURL(audioFile));
  //   audio.onloadedmetadata = () => {
  //     setAudioLength(audio.duration);
  //   };

  //   // setup the audio to be controled
  //   setupAudio();
  // }, [startTime, endTime, audioFile]);

  /*
  const handlePreview = async () => {
    const duration = endTime - startTime;

    if (!isPlaying) {
      setIsPlaying(true);
      if (!audioBufferSource) {
        const ac = new AudioContext();
        setContext(ac);
        const audioBuffer = await ac.decodeAudioData(
          await audioFile.arrayBuffer()
        );
        let ab = ac.createBufferSource();
        ab.buffer = audioBuffer;
        ab.connect(ac.destination);
        ab.start(ac.currentTime, startTime, duration);
        audioBufferSource.stop(endTime);
        ab.onended = () => {
          console.log("ended");
          setIsPlaying(false);
        };
        setAudioBufferSource(ab);
        // Update the slider value based on the current time
        setIntervalID(
          setInterval(() => {
            // console.log(context.currentTime);
            const current =
              ((ac.currentTime + startTime) * 100) / audioBuffer.duration;
            setSliderValue(current);

            if (ac.currentTime + startTime >= endTime) {
              setIsPlaying(false);
              clearInterval(intervalID);
            }
          }, 1000)
        );
      } else {
        context?.resume();
        // audioBufferSource.(audioBufferSource.context.currentTime, startTime, duration);
      }
    } else {
      console.log("pause");
      if (audioBufferSource) {
        // audioBufferSource.stop();
        context?.suspend();
        setIsPlaying(false);
        console.log(intervalID);
        clearInterval(intervalID);
      }
    }
  };
  */

  function getAudioLength(length: number) {
    const minutes = Math.floor(length / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(length % 60)
      .toString()
      .padStart(2, "0");
    // console.log({length, minutes, seconds })
    return `${minutes}:${seconds}`;
  }

  function convertSecondsToTime(seconds: number) {
    const t = new Date(seconds * 1000).toISOString().substring(14, 19);
    // console.log({seconds, t})
    return t;
  }

  function convertTimeToSeconds(time: string) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  }

  return (
    <div className="items-center">
      <div className="flex gap-3 items-center">
        Previews
        <Tooltip
          value={
            "Start time of the song on platforms like TIKTOK. Other platforms also use this for their preview start time."
          }
        />
        <XDSwitch checked={hasPreview}
          onChange={(isChecked) => {
            setHasPreview(isChecked);
          }}
        />
      </div>
      {hasPreview ? (
        <div className="items-center">
          <div className="flex lg:gap-5 gap-3 items-center my-3">
            {/*}
            <Input
              className=""
              id="duration"
              placeholder={convertSecondsToTime(startTime)}
              value={startTime ? convertSecondsToTime(startTime) : 0}
              type="time"
              label=""
              backgroundColor="bg-transparent"
              onChange={handleStartTimeChange}
            />
            -
            <Input
              className=""
              id="duration"
              placeholder={convertSecondsToTime(endTime)}
              value={convertSecondsToTime(endTime)}
              type="time"
              label=""
              backgroundColor="bg-transparent"
              onChange={handleEndTimeChange}
            />*/}
            <div
              className="items-center"
            // This is to show the duration set by the user
            >
              {`${previewDuration} Second${previewDuration <= 1 ? '' : `s`}`}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <PreviewTimeInput value={startTime} onChange={handleStartTimeChange} />
            <span className="border-none border-black px-1">-</span>
            <PreviewTimeInput value={endTime} onChange={handleEndTimeChange} />
          </div>
          {/* <Slider
            // onClick={handlePreview}
            icon={
              isPlaying ? (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    if (context?.state == "running") {
                      context?.suspend();
                      clearInterval(intervalID);
                    }
                  }}
                >
                  pause
                </span>
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    console.log(context?.state);
                    console.log(context?.currentTime);
                    if (context?.state == "suspended") {
                      if (context?.currentTime >= endTime) {
                        // context?.resume();
                        // context?.suspend();
                      }
                      context?.resume();
                    } else {
                      context?.suspend();
                    }
                  }}
                >
                  <SmallPlayIcon />
                </span>
              )
            }
            // This is to get show the full length of the audio
            audioLength={getAudioLength(audioLength)}
          >
            <div className="flex w-full">
              <input
                type="range"
                min="0"
                max={audioLength}
                value={sliderValue}
                className={"thumb-range thumb--zindex-3"}
                onChange={(event) => setSliderValue(Number(event.target.value))}
              />

              <input
              type="range"
              min="0"
              max="100"
              value={sliderValue}
              className="thumb-range thumb--zindex-4"
              onChange={(event) => setSliderValue(Number(event.target.value))}
            />
            </div>
          </Slider> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

function PreviewTimeInput({ value, onChange }: { value: number, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
  const [hours, setHours] = useState(Math.floor(value / 3600));
  const [minutes, setMinutes] = useState(Math.floor(value / 60) % 60);
  const [seconds, setSeconds] = useState(value % 60);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, fn: (v: number) => void) => {
    e.target.value = e.target.value.replace(/^(0)+/, '');
    // console.log({v: e.target.value})
    const value = Math.abs(Number(e.target.value));
    if (value > Number(e.target.max)) {
      e.target.value = e.target.max;
    }

    if (value < Number(e.target.min)) {
      e.target.value = e.target.min;
    }

    if (e.target.value.length > e.target.max.length) {
      // console.log({value, targetValue: e.target.value, target: e.target.value.length, max: e.target.max.length});
      e.target.value = '00';
    }

    if (value && e.target.value.length > 0) {
      // console.log({pad: e.target.value})
      e.target.value = e.target.value.padStart(2, '0');
      // console.log(e.target.value)
    }

    fn(Number(e.target.value));
  }

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.length === 1 && /\D/.test(event.key)) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    onChange({ target: { value: `${hours}:${minutes}:${seconds}` } } as ChangeEvent<HTMLInputElement>);
  }, [hours, minutes, seconds])

  const inputClass = "focus:ring-xd_yellow text-center w-10 p-1 border-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none";
  return (
    <div className="bg-white text-center content-center align-middle flex flex-row items-center border-black border w-fit divide-black">
      <input type='number' onChange={e => handleChange(e, setHours)} defaultValue={hours} className={inputClass} min={0} max={5} onKeyPress={onKeyPress} />
      <span className="border-none border-black px-1">:</span>
      <input type='number' onChange={e => handleChange(e, setMinutes)} defaultValue={minutes} className={inputClass} min={0} max={60} onKeyPress={onKeyPress} />
      <span className="border-none border-black px-1">:</span>
      <input type='number' onChange={e => handleChange(e, setSeconds)} defaultValue={seconds} className={inputClass} min={0} max={60} onKeyPress={onKeyPress} />
    </div>
  );
}

export default TrackPreview;
