import React from "react";
import Header from "../Header/Header";
import SupportBanner from "./SupportBanner";
import SupportForm from "./SupportForm";
import TermsAndPolicies from "../TermsAndPolicies/TermsAndPolicies";
import ListPlaylistSupportRequest from "./ListPlaylistSupportReqest";

function PlaylistSupport() {
  return (
    <div>
      <div>
        <Header children="Playlist Support" />
      </div>
      <div className="my-8">
        <div>
          <SupportBanner />
        </div>
        <div className="my-5">
          <ListPlaylistSupportRequest />
        </div>
        <div className="px-8">
          <TermsAndPolicies />
        </div>
      </div>
    </div>
  );
}

export default PlaylistSupport;
