import React from "react";
import Button from "../Button/Button";
import Modal from "../modal";
import SupportForm from "./SupportForm";

function SupportBanner() {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <div className="border border-transparent rounded-big flex justify-center items-center text-white text-center py-10 bg-PlaylistSupportImg bg-cover bg-no-repeat w-full">
      <Modal
        closeIconColor="black"
        openModal={() => {
          setOpenModal(true);
        }}
        closeModal={() => {
          setOpenModal(false);
        }}
        open={openModal}>
        <div className="flex justify-center">
          <SupportForm
            onsumbit={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </Modal>
      <div>
        <div className="font-semibold md:text-[26px] leading-[31.69px] text-[17px]">
          Whether you're looking to set up your first <br /> campaign, we've got
          you covered!
        </div>
        <div className="my-5 font-semibold md:text-[17px] leading-[20.72px] text-[9px]">
          Getting placed in a playlist is one of the most effective way of
          promoting a song. <br /> We have a growing number of playlist to be
          included in. If you would like to <br /> add your song to our
          playlist, please fill out the form below and submit your <br />{" "}
          marketing drivers to it to our team of curators.
        </div>
        <div>
          <Button
            children="Submit Marketing Drivers"
            color="bg-white"
            className="font-semibold md:text-xs text-[8px]"
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SupportBanner;
