import React from "react";
import data from "../../data/TermsOfUseData";
import TermsOfUse from "./TermsofUse";
import Navbar from "../NavBar/Nav";

function Terms() {
  return (
    <div>
      <Navbar background={true} />
      <div className="flex mx-5 md:mx-96 max-w-[750px]">
        <div>
          <div className="font-semibold text-[28px] my-10">
            Terms & Conditions
          </div>
          {data.map((d, i) => {
            return (
              <div key={i} className="w-full">
                <TermsOfUse header={d.header} body={d.body} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Terms;

